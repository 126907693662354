import { useMemo } from "react";
// Import interfaces
import { Area } from "../helpers/interfaces";
// Import types
import { DateTime } from "../helpers/types";
// Import helpers
import { getAreasFields } from "../helpers/areas";

interface UseAreasProps {
  areas: Area[];
  hourWidth: number;
  sidebarWidth: number;
  height: number;
  timelineHeight: number;
  startDate: DateTime;
  endDate: DateTime;
}

export function useAreas(props: UseAreasProps) {
  const { startDate, endDate, areas } = props;
  const { hourWidth, sidebarWidth, timelineHeight, height } = props;

  const areasSerialized = JSON.stringify(areas);
  const data = useMemo(
    () => {
      return areas.map((area) => {
        const options = {
          area,
          hourWidth,
          sidebarWidth,
          height,
          startDate,
          endDate,
          timelineHeight,
        };
        return getAreasFields(options);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      areasSerialized,
      hourWidth,
      sidebarWidth,
      height,
      startDate,
      endDate,
      timelineHeight,
    ]
  );

  return { data };
}
