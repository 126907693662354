import * as React from "react";

// Import interfaces
import { HoursInDayDiffTime, Line as ILine } from "../helpers/interfaces";

// Import types
import { DateTime } from "../helpers/types";

// Import helpers
import { Layers, getLineStyles } from "../helpers";

// Import styles
import { LineStyled } from "../styles";

// Import components
import { useLine } from "../hooks/useLine";

interface LineProps {
  isTimeline: boolean;
  isVerticalMode: boolean;
  height: number;
  startDate: DateTime;
  endDate: DateTime;
  dayWidth: number;
  hourWidth: number;
  sidebarWidth: number;
  liveRefreshTime: number;
  hoursInDays: HoursInDayDiffTime[];
  renderLine?: (v: ILine) => React.ReactNode;
}

const { lineStyles, LineBox } = LineStyled;

export function Line({
  isTimeline,
  isVerticalMode,
  height,
  renderLine,
  ...rest
}: LineProps) {
  const { positionX, showLine } = useLine(rest);

  if (!showLine) return null;

  const position = {
    height,
    left: positionX,
    zIndex: Layers.Line,
  };

  const styles = getLineStyles({
    isVerticalMode,
    isTimeline,
    lineStyles,
    position,
  });

  if (renderLine)
    return <>{renderLine({ styles, isVerticalMode, isTimeline })}</>;

  return (
    <LineBox
      className="planby-line"
      isVerticalMode={isVerticalMode}
      isTimeline={isTimeline}
      {...position}
    />
  );
}
