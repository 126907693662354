import React from "react";
import { startOfToday } from "date-fns";

// Import interfaces
import {
  Area,
  ChannelWithOmittedUuid,
  DragAndDrop,
  Grid,
  HoursInDay,
  Mode,
  Overlap,
  ProgramOverlaps,
  ProgramWithOmittedUuid,
  Theme,
} from "../helpers/interfaces";

// Import types
import {
  DateTime,
  BaseTimeFormat,
  Position,
  InitialScrollPositions,
  ProgramItem,
  DragMouseUp,
  ResizeMouseUp,
} from "../helpers/types";

// Import helpers
import {
  DAY_WIDTH,
  ITEM_HEIGHT,
  ITEM_OVERSCAN,
  SIDEBAR_WIDTH,
  LIVE_REFRESH_TIME,
  formatTime,
  getConvertedChannels,
  getConvertedPrograms,
  getItemVisibility,
  getSidebarItemVisibility,
  getDayWidthResources,
  getTimeRangeDates,
  getDayResources,
  getTimelineItemVisibility,
  TIMELINE_HEIGHT,
  getTimelineHeight,
  getNumberOfHoursInDays,
  resetLayoutScreenCloneElements,
  checkOverlaps,
  calculateItemDragSinceTill,
  getChannelEpgIndexes,
  setChannelEpgIndexes,
} from "../helpers";

// Import theme
import { theme as defaultTheme } from "../theme";

// Import components
import { useLayout } from "./useLayout";
import { getConvertedGridItems } from "../helpers/grid";

interface useEpgProps {
  isVerticalMode?: boolean;
  isRTL?: boolean;
  isBaseTimeFormat?: BaseTimeFormat;
  isSidebar?: boolean;
  isTimeline?: boolean;
  isLine?: boolean;
  isCurrentTime?: boolean;
  isInitialScrollToNow?: boolean;
  isResize?: boolean;
  initialScrollPositions?: InitialScrollPositions;
  channels: ChannelWithOmittedUuid[];
  epg: ProgramWithOmittedUuid[];
  width?: number;
  height?: number;
  startDate?: DateTime;
  endDate?: DateTime;
  liveRefreshTime?: number;
  mode?: Mode;
  overlap?: Overlap;
  grid?: Grid;
  dnd?: DragAndDrop;
  areas?: Area[];
  hoursInDays?: HoursInDay[];
  theme?: Theme;
  globalStyles?: string;
  dayWidth?: number;
  sidebarWidth?: number;
  timelineHeight?: number;
  itemHeight?: number;
  itemOverscan?: number;
  channelMapKey?: string;
  programChannelMapKey?: string;
}

const defaultStartDateTime = formatTime(startOfToday());
const defaultMode = { type: "day", style: "default" } as Mode;
const defaultOverlap = {
  enabled: false,
  mode: "stack",
  layerOverlapLevel: 0.4,
} as Overlap;
const defaultGrid = {
  enabled: false,
  hoverHighlight: false,
} as Grid;
const defaultDnd = {
  enabled: false,
  mode: "row",
} as DragAndDrop;

export function useEpg(props: useEpgProps) {
  let {
    isVerticalMode = false,
    isRTL = false,
    isResize = false,
    isBaseTimeFormat = false,
    isSidebar = true,
    isTimeline = true,
    isLine = true,
    isCurrentTime = false,
    isInitialScrollToNow = true,
  } = props;

  const { width, height } = props;

  const { channels: channelsEpg, epg } = props;

  const {
    startDate: startDateInput = defaultStartDateTime,
    endDate: endDateInput = "",
    hoursInDays: customHoursInDays = [],
  } = props;

  const {
    initialScrollPositions = {},
    liveRefreshTime = LIVE_REFRESH_TIME,
    mode: customMode = defaultMode,
    overlap: customOverlap = defaultOverlap,
    areas = [],
    grid = defaultGrid,
    dnd = defaultDnd,
    theme: customTheme,
    globalStyles,
  } = props;

  const {
    dayWidth: customDayWidth = DAY_WIDTH,
    sidebarWidth = SIDEBAR_WIDTH,
    timelineHeight: customTimelineHeight = TIMELINE_HEIGHT,
    itemHeight = ITEM_HEIGHT,
    itemOverscan = ITEM_OVERSCAN,
  } = props;

  const { channelMapKey = "uuid", programChannelMapKey = "channelUuid" } =
    props;

  const mode = { ...defaultMode, ...customMode };
  const overlap = { ...defaultOverlap, ...customOverlap };
  const isOverlapEnabled = overlap.enabled;
  const isMultirowsDnd = dnd.mode === "multi-rows";

  // Get converted start and end dates
  const { startDate, endDate } = getTimeRangeDates(
    startDateInput,
    endDateInput
  );

  // Get hours in days
  const serializedHoursInDays = JSON.stringify(customHoursInDays);
  const hoursInDays = React.useMemo(
    () => getNumberOfHoursInDays({ customHoursInDays, startDate }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serializedHoursInDays]
  );

  // Get days resources eg. number of days, dates and hours
  const { isToday, currentDate, ...daysResources } = React.useMemo(
    () =>
      getDayResources({
        startDate,
        endDate,
        modeType: mode.type,
      }),
    [startDate, endDate, mode.type]
  );

  // Get day and hour width of the day
  const { hourWidth, dayWidth, ...dayWidthResourcesProps } = React.useMemo(
    () =>
      getDayWidthResources({
        dayWidth: customDayWidth,
        startDate,
        endDate,
        hoursInDays,
        modeType: mode.type,
      }),
    [customDayWidth, startDate, endDate, hoursInDays, mode.type]
  );

  // -------- State --------
  const [update, setForceUpdate] = React.useReducer((x) => x + 1, 0);
  const [dndChannelUuid, setDndChannelUuid] = React.useState({
    index: -1,
    uuid: "",
  });

  const [newOverlaps, setNewOverlaps] = React.useState<number>(() => 0);
  let [overlaps, setOverlaps] = React.useState<ProgramOverlaps>({});
  const [channelOverlapsCount, setChannelOverlapsCount] = React.useState<
    Record<string, number>
  >({});

  React.useEffect(() => {
    setForceUpdate();
    setDndChannelUuid({ index: -1, uuid: "" });
  }, [isVerticalMode]);

  const { containerRef, scrollBoxRef, ...layoutProps } = useLayout({
    isVerticalMode,
    isToday,
    isInitialScrollToNow,
    initialScrollPositions,
    startDate,
    endDate,
    sidebarWidth,
    width,
    height,
    hourWidth,
    currentDate,
    hoursInDays,
  });

  React.useEffect(() => {
    return () => {
      resetLayoutScreenCloneElements();
    };
  }, []);

  const { scrollX, scrollY, layoutWidth, layoutHeight } = layoutProps;
  const { onScroll, onScrollToNow, onScrollTop, onScrollLeft, onScrollRight } =
    layoutProps;

  //-------- Variables --------
  const timelineHeight = getTimelineHeight(customTimelineHeight, mode);

  const channelsEpgSerialized = React.useMemo(
    () => JSON.stringify(channelsEpg),
    [channelsEpg]
  );
  const channelOverlapsCountSerialized = React.useMemo(
    () => channelOverlapsCount,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(channelOverlapsCount)]
  );
  const channels = React.useMemo(
    () =>
      getConvertedChannels(
        isOverlapEnabled,
        overlap.mode,
        overlap.layerOverlapLevel as number,
        channelsEpg,
        itemHeight,
        channelMapKey,
        channelOverlapsCount
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isOverlapEnabled,
      overlap.mode,
      overlap.layerOverlapLevel,
      channelsEpgSerialized,
      channelOverlapsCountSerialized,
      itemHeight,
      channelMapKey,
      newOverlaps,
    ]
  );

  const startDateTime = formatTime(startDate);
  const endDateTime = formatTime(endDate);
  const channelsSerialized = React.useMemo(
    () => JSON.stringify(channels),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [channels]
  );
  const overlapsSerialized = React.useMemo(
    () => overlaps,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(overlaps)]
  );

  const { programs, programOverlaps } = React.useMemo(
    () => {
      const data = getConvertedPrograms({
        isVerticalMode,
        isOverlapEnabled,
        programChannelMapKey,
        data: epg,
        channels,
        startDate: startDateTime,
        endDate: endDateTime,
        hoursInDays,
        itemHeight,
        hourWidth,
      });

      if (isOverlapEnabled) {
        const _overlaps = overlaps;

        const { overlaps: itemsOverlaps, channelOverlaps } = checkOverlaps(
          isMultirowsDnd,
          isVerticalMode,
          dndChannelUuid,
          _overlaps,
          data
        );

        setChannelOverlapsCount({
          ...channelOverlapsCount,
          ...channelOverlaps,
        });
        setNewOverlaps((prev) => prev + 1);
        setOverlaps(itemsOverlaps);
        return {
          programs: data,
          programOverlaps: itemsOverlaps,
        };
      }
      return { programs: data, programOverlaps: {} };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isMultirowsDnd,
      isOverlapEnabled,
      isVerticalMode,
      epg,
      channelsSerialized,
      overlapsSerialized,
      startDateTime,
      endDateTime,
      itemHeight,
      hourWidth,
      hoursInDays,
      programChannelMapKey,
      update,
    ]
  );

  const gridItems = React.useMemo(
    () => {
      if (!grid.enabled) return [];
      return getConvertedGridItems({
        isVerticalMode,
        channels,
        dayWidth,
        hourWidth,
        timelineHeight,
        sidebarWidth,
        mode,
        dayWidthResources: dayWidthResourcesProps,
        daysResources,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      grid.enabled,
      channelsSerialized,
      mode.type,
      dayWidthResourcesProps.numberOfHoursInDay,
      dayWidthResourcesProps.numberOfMonths,
      daysResources.numberOfDays,
      dayWidth,
      hourWidth,
      sidebarWidth,
      timelineHeight,
      isVerticalMode,
    ]
  );

  const theme: Theme = { ...defaultTheme, ...customTheme };

  // -------- Handlers --------
  const isProgramVisible = React.useCallback(
    (position: Position, overlapsCount: number) => {
      return getItemVisibility({
        isVerticalMode,
        itemOverscan,
        overlapsCount,
        position,
        scrollY,
        scrollX,
        containerHeight: layoutHeight,
        containerWidth: layoutWidth,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVerticalMode, itemOverscan, layoutHeight, layoutWidth, scrollY, scrollX]
  );

  const isChannelVisible = React.useCallback(
    (position: Pick<Position, "top" | "height">) =>
      getSidebarItemVisibility({
        isVerticalMode,
        itemOverscan,
        position,
        scrollX,
        scrollY,
        containerHeight: layoutHeight,
        containerWidth: layoutWidth,
      }),

    [isVerticalMode, itemOverscan, scrollY, scrollX, layoutHeight, layoutWidth]
  );

  const isTimelineVisible = React.useCallback(
    (position: Pick<Position, "left" | "width">) =>
      getTimelineItemVisibility({
        position,
        scrollY,
        scrollX,
        containerHeight: layoutHeight,
        containerWidth: layoutWidth,
        isVerticalMode,
      }),
    [scrollY, scrollX, layoutHeight, layoutWidth, isVerticalMode]
  );

  const resizeMouseUp = React.useCallback(
    (props: ResizeMouseUp) => {
      const newTime = calculateItemDragSinceTill({
        ...props,
        hourWidth,
        startDate,
      });

      epg[props.index].since = newTime.since;
      epg[props.index].till = newTime.till;
      setDndChannelUuid({
        index: epg[props.index].channelIndex,
        uuid: epg[props.index][programChannelMapKey] as string,
      });
      setForceUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVerticalMode, startDate, hourWidth, programs]
  );

  const dragMouseUp = React.useCallback(
    (props: DragMouseUp) => {
      const newTime = calculateItemDragSinceTill({
        ...props,
        hourWidth,
        startDate,
      });
      const element = { ...epg[props.index] };

      const checkChannelTopOutRange = () => {
        const elementChannel = element.channelPosition;
        if (
          props.top > elementChannel.top + elementChannel.height - 1 ||
          props.top < elementChannel.top - 1
        ) {
          return true;
        }
        return false;
      };
      const checkChannelTopInRange = () => {
        const elementChannel = element.channelPosition;
        return elementChannel.top <= props.top &&
          props.top <= elementChannel.top + elementChannel.height
          ? true
          : false;
      };

      const isChannelTopInRange = checkChannelTopInRange();

      const isChannelTopOutRange = checkChannelTopOutRange();
      if (isMultirowsDnd && isChannelTopOutRange) {
        const newChannel = channels.find(
          (channel) => channel.position.top === props.top
        );
        const newChannelRange = channels.find(
          (channel) =>
            channel.position.top <= props.top &&
            props.top <= channel.position.top + channel.position.height
        );

        if (newChannel) {
          const newChannelEpgIndexes = getChannelEpgIndexes(
            newChannel[channelMapKey]
          );
          if (newChannelEpgIndexes) {
            epg[props.index].since = newTime.since;
            epg[props.index].till = newTime.till;
            epg[props.index][programChannelMapKey] = newChannel[
              channelMapKey
            ] as string;
            epg[props.index].channelIndex = newChannel.index as number;
            epg[props.index].channelPosition = {
              top: newChannel.position.top,
              height: newChannel.position.height,
              left: newChannel.position.left,
            };
          } else {
            setChannelEpgIndexes({
              uuid: newChannel[channelMapKey],
              first: props.index,
              last: props.index,
            });
            epg[props.index].since = newTime.since;
            epg[props.index].till = newTime.till;
            epg[props.index][programChannelMapKey] = newChannel[
              channelMapKey
            ] as string;
            epg[props.index].channelIndex = newChannel.index as number;
            epg[props.index].channelPosition = {
              top: newChannel.position.top,
              height: newChannel.position.height,
              left: newChannel.position.left,
            };
          }
        } else if (newChannelRange) {
          epg[props.index].since = newTime.since;
          epg[props.index].till = newTime.till;
          epg[props.index][programChannelMapKey] = newChannelRange[
            channelMapKey
          ] as string;
          epg[props.index].channelIndex = newChannelRange.index as number;
          epg[props.index].channelPosition = {
            top: newChannelRange.position.top,
            height: newChannelRange.position.height,
            left: newChannelRange.position.left,
          };
        }
      } else if (isMultirowsDnd && isChannelTopInRange) {
        epg[props.index].since = newTime.since;
        epg[props.index].till = newTime.till;
      } else {
        epg[props.index].since = newTime.since;
        epg[props.index].till = newTime.till;
        setDndChannelUuid({
          index: epg[props.index].channelIndex,
          uuid: epg[props.index][programChannelMapKey] as string,
        });
      }

      setForceUpdate();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMultirowsDnd, startDate, hourWidth, programs]
  );

  const handleGetLayoutData = React.useCallback(
    (events: ProgramItem[]) => events.map((event) => ({ ...event.data })),
    []
  );

  const getEpgProps = () => ({
    isVerticalMode,
    isRTL,
    isSidebar,
    isLine,
    isTimeline,
    width,
    height,
    sidebarWidth,
    timelineHeight,
    ref: containerRef,
    theme,
    globalStyles,
  });

  const getLayoutProps = () => ({
    isVerticalMode,
    isRTL,
    isBaseTimeFormat,
    isSidebar,
    isTimeline,
    isLine,
    isCurrentTime,
    isProgramVisible,
    isChannelVisible,
    isTimelineVisible,
    isToday,
    isResize,
    programs,
    programOverlaps,
    channels,
    channelOverlapsCount,
    layerOverlapLevel: overlap.layerOverlapLevel as number,
    grid,
    gridItems,
    dnd,
    startDate,
    endDate,
    hoursInDays,
    liveRefreshTime,
    scrollY,
    dayWidth,
    hourWidth,
    sidebarWidth,
    timelineHeight,
    itemHeight,
    currentDate,
    mode,
    overlapMode: overlap.mode,
    overlap,
    areas,
    onScroll,
    dragMouseUp,
    resizeMouseUp,
    ...daysResources,
    ...dayWidthResourcesProps,
    ref: scrollBoxRef,
  });

  return {
    getEpgProps,
    getLayoutProps,
    getLayoutData: handleGetLayoutData,
    onScrollToNow,
    onScrollTop,
    onScrollLeft,
    onScrollRight,
    scrollY,
    scrollX,
  };
}
