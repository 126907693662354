import { useMemo } from "react";
import { format } from "date-fns";

// Import interfaces
import { Timeline as ITimeline } from "../helpers/interfaces";

// Import helpers
import {
  TIME_FORMAT,
  generateArray,
  generateTimelineSlots,
  HOURS_IN_DAY,
  getTimelineMonthsWidth,
  getFormattedWeekMonthDate,
  getTodayHoursInDays,
} from "../helpers";

type UseTimelineProps = ITimeline;

export function useTimeline(props: UseTimelineProps) {
  const { isBaseTimeFormat, isRTL, isSidebar, isVerticalMode } = props;
  const { dayWidth, hourWidth, sidebarWidth, timelineHeight } = props;
  const {
    mode,
    startDate,
    endDate,
    hoursInDays,
    days,
    liveRefreshTime,
    months,
    numberOfDays,
    numberOfHoursInDay,
    numberOfMonths,
    offsetStartHoursRange,
    renderCurrentTime,
  } = props;

  // Handlers
  const getTime = (index: number | string) => {
    if (typeof index === "string") return { time: index, isNewDay: true };

    const date = new Date();
    const baseDate = format(date, TIME_FORMAT.DATE);
    const time = index < 10 ? `0${index}` : index;

    if (isBaseTimeFormat) {
      const date = new Date(`${baseDate}T${time}:00:00`);
      const baseFormat = format(date, TIME_FORMAT.BASE_HOURS_TIME)
        .toLowerCase()
        .replace(/\s/g, "");

      return { time: baseFormat, isNewDay: false };
    }

    return { time: `${time}:00`, isNewDay: false };
  };

  const formatWeekMonthDate = (date: string) =>
    getFormattedWeekMonthDate({ date, mode, isBaseTimeFormat });

  const getDayMonthName = (date: string) => {
    const dateFormat =
      mode.type === "week" ? TIME_FORMAT.DAY : TIME_FORMAT.YEAR;
    return format(new Date(date), dateFormat);
  };

  const getCurrentTimeProps = () => ({
    isBaseTimeFormat,
    isVerticalMode,
    isRTL,
    mode,
    startDate,
    endDate,
    hoursInDays,
    dayWidth,
    timelineHeight,
    hourWidth,
    sidebarWidth,
    liveRefreshTime,
    renderCurrentTime,
  });

  const getTimelineProps = () => ({
    isSidebar,
    isVerticalMode,
    dayWidth,
    sidebarWidth,
    timelineHeight,
  });

  // Variables
  const timeSlots = useMemo(() => {
    const options = {
      isBaseTimeFormat,
      days,
      hoursInDays,
      months,
      numberOfDays,
      numberOfHoursInDay,
      numberOfMonths,
      offsetStartHoursRange,
    };

    return generateTimelineSlots(mode.type, options);
  }, [
    mode.type,
    isBaseTimeFormat,
    days,
    hoursInDays,
    months,
    numberOfDays,
    numberOfHoursInDay,
    numberOfMonths,
    offsetStartHoursRange,
  ]);

  const isWeekMode = mode.type === "week";
  const isMonthMode = mode.type === "month";
  const isWeekMonthMode = isWeekMode || isMonthMode;
  const isTodayInHoursInDays = getTodayHoursInDays(hoursInDays);
  const areHoursInDays = hoursInDays.length > 0;

  const weekDayWidth = hourWidth * HOURS_IN_DAY;
  const monthsWidth = useMemo(
    () => getTimelineMonthsWidth({ months, weekDayWidth }),
    [months, weekDayWidth]
  );
  const dividers = generateArray(4);

  return {
    isWeekMonthMode,
    isMonthMode,
    isTodayInHoursInDays,
    areHoursInDays,
    time: timeSlots,
    weekDayWidth,
    monthsWidth,
    timelineHeight,
    dividers,
    formatWeekMonthDate,
    getTime,
    getDayMonthName,
    getTimelineProps,
    getCurrentTimeProps,
  };
}
