import { Mode, Grid as IGrid, HoursInDayDiffTime } from "../helpers/interfaces";
import { BaseTimeFormat, ChannelWithPosition, Position } from "./types";
import { generateArray } from "./common";
import { HOURS_IN_DAY } from "./variables";
import { generateTimelineSlots, getTimelineMonthsWidth } from "./timeline";

interface ConvertedGridItems {
  isVerticalMode: boolean;
  channels: ChannelWithPosition[];
  mode: Mode;
  dayWidth: number;
  hourWidth: number;
  timelineHeight: number;
  sidebarWidth: number;
  dayWidthResources: {
    numberOfMonths: number;
    numberOfHoursInDay: number;
    monthWidth: number;
    offsetStartHoursRange: number;
  };
  daysResources: {
    numberOfDays: number;
    days: string[];
    months: string[];
  };
}
export const getConvertedGridItems = ({
  isVerticalMode,
  channels,
  dayWidth,
  hourWidth,
  timelineHeight,
  sidebarWidth,
  mode,
  dayWidthResources,
  daysResources,
}: ConvertedGridItems) => {
  const isMonthMode = mode.type === "month";

  const numberOfSlots = () => {
    if (mode.type === "day") return dayWidthResources.numberOfHoursInDay;
    if (mode.type === "week") return daysResources.numberOfDays;
    return dayWidthResources.numberOfMonths;
  };

  const monthsWidth = () =>
    getTimelineMonthsWidth({
      months: daysResources.months,
      weekDayWidth: hourWidth * HOURS_IN_DAY,
    });

  const getGridItemPosition = (channel: ChannelWithPosition, index: number) => {
    let width = 0;
    let left = 0;
    if (isMonthMode) {
      width = monthsWidth()[index].width;
      left = monthsWidth()[index].left;
    } else {
      width = dayWidth / numberOfSlots();
      left = width * index;
    }
    let position = {
      top: channel.position.top,
      left,
      width,
      height: channel.position.height,
      edgeEnd: left + width,
    };

    if (isVerticalMode) {
      position = {
        ...position,
        left: position.top + timelineHeight,
        top: position.left + sidebarWidth,
        width: position.height,
        height: position.width,
      };
    } else {
      position = {
        ...position,
        top: position.top + timelineHeight,
        left: left + sidebarWidth,
        width,
        height: position.height,
      };
    }

    return { position, channel };
  };
  return channels.flatMap((channel) =>
    generateArray(numberOfSlots()).map((_, index) =>
      getGridItemPosition(channel, index)
    )
  );
};

// On grid item click
interface ClickGridItem {
  isVerticalMode: boolean;
  isBaseTimeFormat: BaseTimeFormat;
  index: number | undefined;
  item: {
    position: Position;
    channel: ChannelWithPosition;
  };
  grid: IGrid;
  mode: Mode;
  dayWidth: number;
  hourWidth: number;
  timelineHeight: number;
  sidebarWidth: number;
  days: string[];
  hoursInDays: HoursInDayDiffTime[];
  months: string[];
  numberOfDays: number;
  numberOfHoursInDay: number;
  numberOfMonths: number;
  offsetStartHoursRange: number;
}
export const getClickGridItemData = ({
  isVerticalMode,
  index,
  item,
  grid,
  mode,
  dayWidth,
  hourWidth,
  timelineHeight,
  sidebarWidth,
  // dayWidthResources,
  // daysResources,
  isBaseTimeFormat,
  days,
  hoursInDays,
  months,
  numberOfDays,
  numberOfHoursInDay,
  numberOfMonths,
  offsetStartHoursRange,
}: ClickGridItem) => {
  let { position, channel } = item;
  const isDayMode = mode.type === "day";
  let newPosition = {} as Position;
  if (isVerticalMode) {
    newPosition = {
      ...position,
      top: position.left,
      left: position.top - timelineHeight,
      edgeEnd: position.left + hourWidth - timelineHeight,
    };
  } else {
    newPosition = {
      ...position,
      top: position.top,
      left: position.left - sidebarWidth,
      edgeEnd: position.edgeEnd - sidebarWidth,
    };
  }

  const options = {
    isBaseTimeFormat,
    days,
    hoursInDays,
    months,
    numberOfDays,
    numberOfHoursInDay,
    numberOfMonths,
    offsetStartHoursRange,
  };
  const timeSlots = generateTimelineSlots(mode.type, options);

  const left = isDayMode
    ? newPosition.left + (index as number) * (hourWidth / 4)
    : newPosition.left;
  const edgeEnd = isDayMode ? left + hourWidth / 4 : newPosition.edgeEnd;
  const itemClickProps = {
    since: "00:00:00",
    till: "00:00:00",
    date: "",
    channelUuid: channel.uuid,
  };

  if (isDayMode) {
    const gridItemProps = getDividerGridProps({
      left,
      edgeEnd,
      timeSlots,
      channel,
      days,
      dayWidth,
      hourWidth,
      index,
    });
    grid.onGridItemClick?.(gridItemProps);
  }

  if (mode.type === "week") {
    const index = Math.floor(left / (hourWidth * 24));
    const options = {
      ...itemClickProps,
      date: timeSlots[index] as string,
    };
    grid.onGridItemClick?.(options);
  }

  if (mode.type === "month") {
    const index = Math.floor(left / (hourWidth * 24 * 30));
    const options = {
      ...itemClickProps,
      date: timeSlots[index] as string,
    };
    grid.onGridItemClick?.(options);
  }
};

interface DividerGridProps {
  left: number;
  edgeEnd: number;
  timeSlots: (string | number)[];
  channel: ChannelWithPosition;
  days: string[];
  hourWidth: number;
  dayWidth: number;
  index: number | undefined;
}
const getDividerGridProps = ({
  left,
  edgeEnd,
  timeSlots,
  channel,
  days,
  hourWidth,
  dayWidth,
  index,
}: DividerGridProps) => {
  let since = "";
  let till = "";
  const dayTimeSlots = timeSlots.map((time) =>
    typeof time === "number" ? time : 0
  );
  const sinceTimeInHours = Math.floor(left / hourWidth); // Assuming 4 hours per hourWidth
  const tillTimeInHours = Math.floor(edgeEnd / hourWidth); // Assuming 4 hours per hourWidth
  const getTime = (index: number) =>
    (dayTimeSlots[index] as number) < 10
      ? "0" + dayTimeSlots[index]
      : dayTimeSlots[index];
  const newSince = getTime(sinceTimeInHours);
  const newTill = getTime(tillTimeInHours);
  const newSinceMin = index === 0 ? `00` : (index as number) * 15;

  const newTillMin =
    (index as number) + 1 === 0 ? `00` : ((index as number) + 1) * 15;

  since = `${newSince}:${newSinceMin}:00`;
  till = `${newTill}:${newTillMin}:00`;

  if (newTillMin === 60) {
    const newNextTill =
      (dayTimeSlots[sinceTimeInHours + 1] as number) < 10
        ? "0" + dayTimeSlots[sinceTimeInHours + 1]
        : dayTimeSlots[tillTimeInHours];
    till = newTill ? `${newNextTill}:00:00 ` : "00:00:00";
  }
  const singleDayWidth = dayWidth / (days.length - 1);
  const dayIndex = Math.floor(left / singleDayWidth);
  const options = {
    since,
    till,
    date: days[dayIndex],
    channelUuid: channel.uuid,
  };
  return options;
};
