import {
  ChannelStyled,
  CurrentTimeStyled,
  ProgramStyled,
  TimelineStyled,
} from "./styles";

// Import interfaces
import {
  Line as ILine,
  Theme as ITheme,
  Program as IProgram,
  Timeline as ITimeline,
  CurrentTimeIndicator as ICurrentTimeIndicator,
  Mode,
  GridItemProps as IGridItemProps,
  DragAndDrop,
} from "./helpers/interfaces";
import {
  ProgramItem as ProgramItemType,
  ChannelItem as IChannelItem,
  ChannelWithPosition,
  BaseTimeFormat as BaseTimeFormatType,
  DragMouseUp,
  ResizeMouseUp,
} from "./helpers/types";

// Types
export type Channel = ChannelWithPosition;
export type ChannelItem = IChannelItem;
export type CurrentTimeIndicator = ICurrentTimeIndicator;
export type Line = ILine;
export type Program = IProgram;
export type ProgramItem = {
  program: ProgramItemType;
  isRTL: boolean;
  isVerticalMode: boolean;
  isBaseTimeFormat: BaseTimeFormatType;
  liveRefreshTime: number;
  mode: Mode;
  dnd: DragAndDrop;
  dayWidth: number;
  itemHeight: number;
  contentHeight: number;
  hourWidth: number;
  dragMouseUp: (data: DragMouseUp) => void;
  resizeMouseUp: (data: ResizeMouseUp) => void;
};
export type GridItemProps = IGridItemProps;
export type Timeline = ITimeline;
export type Theme = ITheme;

// Components
export { Layout, CurrentTime } from "./components";
export { Epg } from "./Epg";
export { useEpg, useProgram, useTimeline } from "./hooks";

// Styles
const { ChannelBox, ChannelLogo } = ChannelStyled;

const { CurrentTimeBox, CurrentTimeContent } = CurrentTimeStyled;

const {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  ProgramResizeHandle,
} = ProgramStyled;

const {
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDivider,
  TimelineDividers,
  TimelineWeekMonthBox,
  TimelineWeekMonthDate,
} = TimelineStyled;

export {
  // Channel
  ChannelBox,
  ChannelLogo,
  // CurrentTime
  CurrentTimeBox,
  CurrentTimeContent,
  // Program
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
  ProgramResizeHandle,
  // Timeline
  TimelineWrapper,
  TimelineBox,
  TimelineWeekMonthBox,
  TimelineWeekMonthDate,
  TimelineTime,
  TimelineDividers,
  TimelineDivider,
};
