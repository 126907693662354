import { useLayoutEffect, useEffect } from "react";
import {
  Interval,
  isToday as isTodayFns,
  format,
  differenceInHours,
  differenceInDays,
  startOfDay,
  eachDayOfInterval,
  eachMonthOfInterval,
  differenceInMonths,
  startOfMonth,
  addMonths,
} from "date-fns";

// Import interfaces
import {
  HoursInDayDiffTime,
  Mode,
  Overlap,
  ProgramOverlaps,
} from "./interfaces";

// Import types
import { ProgramItem } from "./types";

// Import variables
import { HOURS_IN_DAY, OVERLAP_MODES, TIME_FORMAT } from "./variables";

type DateTime = string | number | Date;

type OmitObjectType = { [key: string]: any };
export const omit = (obj: OmitObjectType, ...props: string[]) => {
  const result = { ...obj };

  for (const property of props) {
    delete result[property];
  }

  return result;
};

export const generateArray = (num: number) => new Array(num).fill("");

interface OverlapProgramOptions {
  isVerticalMode: boolean;
  program: ProgramItem;
  programOverlaps: ProgramOverlaps;
  layerOverlapLevel: number;
  overlap: Overlap;
  overlapMode: Overlap["mode"];
}
const getOverlapProgramOptions = ({
  isVerticalMode,
  program,
  programOverlaps,
  layerOverlapLevel,
  overlapMode,
}: OverlapProgramOptions) => {
  const { data, position } = program;
  const linkedProgramOverlaps = programOverlaps[data.channelUuid];

  if (linkedProgramOverlaps) {
    const programOverlapIndex = linkedProgramOverlaps.findIndex(
      (element) => element.data.id === data.id
    );
    const overlapProgram = linkedProgramOverlaps[programOverlapIndex];

    if (overlapProgram) {
      if (
        overlapMode === OVERLAP_MODES.LAYER &&
        overlapProgram.data.channelPosition.top < overlapProgram.position.top
      ) {
        let newTop = overlapProgram.position.top * layerOverlapLevel;
        if (overlapProgram.data.channelPosition.top !== 0) {
          newTop =
            overlapProgram.data.channelPosition.top +
            Math.abs(
              overlapProgram.data.channelPosition.top -
                overlapProgram.position.top
            ) *
              layerOverlapLevel;
        }

        let position = {
          ...overlapProgram.position,
          top: newTop,
        };

        if (isVerticalMode) {
          position = switchPosition({
            ...overlapProgram.position,
            top: newTop,
          });
        }

        return {
          ...program,
          isOverlap: true,
          position,
        };
      }

      return {
        ...program,
        position: isVerticalMode
          ? switchPosition(overlapProgram.position)
          : overlapProgram.position,
      };
    } else {
      return {
        ...program,
        position,
      };
    }
  }
  const { width, height, top, left } = position;
  return {
    ...program,
    position: { width, height, top, left },
  };
};

export const getProgramOptions = ({
  isVerticalMode,
  program,
  overlap,
  ...rest
}: OverlapProgramOptions) => {
  if (overlap.enabled) {
    return getOverlapProgramOptions({
      isVerticalMode,
      program,
      overlap,
      ...rest,
    });
  }

  const { width, height, top, left } = program.position;
  return {
    ...program,
    position: { width, height, top, left },
  };
};

export const useIsomorphicLayoutEffect = () =>
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const getHourWidth = (dayWidth: number) => dayWidth / HOURS_IN_DAY;

export const getDate = (date: DateTime) => new Date(date);

const abs = (num: number) => Math.abs(num);
interface DayWidth {
  dayWidth: number;
  startDate: DateTime;
  endDate: DateTime;
  hoursInDays: HoursInDayDiffTime[];
  modeType: Mode["type"];
}
export const getDayWidthResources = ({
  dayWidth,
  startDate,
  endDate,
  hoursInDays,
  modeType,
}: DayWidth) => {
  const defaultOptions = {
    hourWidth: 0,
    numberOfMonths: 0,
    numberOfHoursInDay: 0,
    monthWidth: 0,
    offsetStartHoursRange: 0,
    dayWidth: 0,
  };

  const startDateTime = getDate(startDate);
  const endDateTime = getDate(endDate);

  if (endDateTime < startDateTime) {
    console.error(
      `Invalid endDate property. Value of endDate must be greater than startDate. Props: startDateTime: ${startDateTime}, endDateTime: ${endDateTime}`
    );
  }
  if (modeType === "week" || modeType === "month") {
    const endOfMonthTime = startOfMonth(addMonths(endDateTime, 1));
    const numberOfMonthInMonthMode = differenceInMonths(
      endOfMonthTime,
      startDateTime
    );
    const numberOfDaysInWeekMode = differenceInDays(endDateTime, startDateTime);
    const dayWidthInWeekMode = Math.floor(dayWidth / numberOfDaysInWeekMode);
    const hourWidth = dayWidthInWeekMode / HOURS_IN_DAY;
    const newDayWidthInWeekMode =
      hourWidth * HOURS_IN_DAY * numberOfDaysInWeekMode;

    return {
      ...defaultOptions,
      hourWidth: abs(hourWidth),
      dayWidth: abs(newDayWidthInWeekMode),
      numberOfMonths: numberOfMonthInMonthMode,
    };
  }

  if (hoursInDays.length > 0) {
    const numberOfHoursInDay = hoursInDays.reduce(
      (acc, curr) => acc + curr.diffInHours,
      0
    );
    const hourWidth = dayWidth / numberOfHoursInDay;
    const newDayWidth = hourWidth * numberOfHoursInDay;

    return {
      ...defaultOptions,
      hourWidth: abs(hourWidth),
      dayWidth: abs(newDayWidth),
      numberOfHoursInDay: abs(numberOfHoursInDay),
    };
  }

  const offsetStartHoursRange = differenceInHours(
    startDateTime,
    startOfDay(startDateTime)
  );

  const numberOfHoursInDay = differenceInHours(endDateTime, startDateTime);
  const hourWidth = Math.floor(dayWidth / numberOfHoursInDay);
  const newDayWidth = hourWidth * numberOfHoursInDay;

  return {
    ...defaultOptions,
    hourWidth: abs(hourWidth),
    dayWidth: abs(newDayWidth),
    numberOfHoursInDay: abs(numberOfHoursInDay),
    offsetStartHoursRange: abs(offsetStartHoursRange),
  };
};

const convertDate = (date: DateTime) => {
  const newDate = (date as string).replace(/T.*/, "");
  return getDate(newDate);
};

export const getDayResources = ({
  startDate,
  endDate,
  modeType,
}: Pick<DayWidth, "startDate" | "endDate" | "modeType">) => {
  const startDateFormat = format(convertDate(startDate), TIME_FORMAT.DATE);
  const endDateFormat = format(convertDate(endDate), TIME_FORMAT.DATE);

  const modeIncrementValue = modeType === "day" ? 1 : 0;
  const diffDays =
    differenceInDays(getDate(endDateFormat), getDate(startDateFormat)) +
    modeIncrementValue;

  const startToEndInterval: Interval = {
    start: getDate(startDate),
    end: getDate(endDate),
  };

  const days = eachDayOfInterval(startToEndInterval).map((day) =>
    format(day, TIME_FORMAT.DATE)
  );

  const months = eachMonthOfInterval(startToEndInterval).map((day) =>
    format(day, TIME_FORMAT.DATE)
  );

  const dates = days.map((day) => isTodayFns(new Date(day)));
  let isToday = dates.some((day) => day === true);
  let currentDate = days[dates.indexOf(true)];

  return {
    isToday,
    currentDate,
    numberOfDays: diffDays,
    days,
    months,
  };
};

export const switchPosition = (programPosition: ProgramItem["position"]) => ({
  ...programPosition,
  top: programPosition.left,
  left: programPosition.top,
  width: programPosition.height,
  height: programPosition.width,
});
