// Import types
import { ChannelWithPosition, ProgramWithPosition } from "./types";

type LayoutScreenClone = Record<string, Record<string, ProgramWithPosition>>;

let LayoutScreenCloneCache = {} as LayoutScreenClone;

const setChannel = (event: ProgramWithPosition) => {
  const isChannel = LayoutScreenCloneCache[event.data.channelUuid];
  if (!isChannel) {
    LayoutScreenCloneCache[event.data.channelUuid] = {};
    return LayoutScreenCloneCache;
  }
  return LayoutScreenCloneCache;
};

const updateLayoutScreenCloneElement = (data: LayoutScreenClone) =>
  (LayoutScreenCloneCache = data);

export const setLayoutScreenCloneElement = (
  event: ProgramWithPosition,
  index: number
) => {
  const channels = setChannel(event);
  const program = channels[event.data.channelUuid][event.data.id];
  if (!program) {
    event["data"].index = index;
    channels[event.data.channelUuid][event.data.id] = event;
  }
  updateLayoutScreenCloneElement(channels);
};

export const deleteLayoutScreenCloneElement = (event: ProgramWithPosition) => {
  const channels = setChannel(event);
  const program = channels[event.data.channelUuid][event.data.id];
  if (program) {
    delete channels[event.data.channelUuid][event.data.id];
  }
  updateLayoutScreenCloneElement(channels);
};

export const getLayoutScreenCloneElements = () => LayoutScreenCloneCache;
export const resetLayoutScreenCloneElements: () => void = () =>
  (LayoutScreenCloneCache = {} as LayoutScreenClone);

export const getChannelsContentHeight = (channels: ChannelWithPosition[]) =>
  channels.reduce((acc, channel) => (acc += channel.position.height), 0);

// Channel epg indexes
type ChannelEpgIndex = Record<
  string,
  { uuid: string; first: number; last: number }
>;
let channelEpgIndexesCache = {} as ChannelEpgIndex;

export const setChannelEpgIndexes = ({
  uuid,
  first = 0,
  last = 0,
}: {
  uuid: string;
  first?: number;
  last?: number;
}) => {
  channelEpgIndexesCache[uuid] = {
    first,
    last,
    uuid,
  };
};
export const getChannelEpgIndexes = (uuid: string) => {
  return channelEpgIndexesCache[uuid];
};

export const resetChannelsEpgIndexes: () => void = () =>
  (channelEpgIndexesCache = {} as ChannelEpgIndex);
