import { format, getDaysInMonth, parse } from "date-fns";
// Import interfaces
import { HoursInDayDiffTime, Mode } from "./interfaces";
// Import variables
import {
  HOURS_IN_DAY,
  TIMELINE_HEIGHT_MODERN_STYLE,
  TIME_FORMAT,
} from "./variables";
// Import helpers
import { generateArray } from "./common";

const { BASE_DAY_MONTH, DAY_MONTH } = TIME_FORMAT;
const generateDayHours = (
  isBaseTimeFormat: boolean,
  dayIndex: number,
  days: string[]
) =>
  Array.from({ length: HOURS_IN_DAY }, (_, i) => {
    if (i === 0) {
      const timeFormat = isBaseTimeFormat ? BASE_DAY_MONTH : DAY_MONTH;
      const date = parse(days[dayIndex], TIME_FORMAT.DATE, new Date());
      return dayIndex === 0 ? i : format(date, timeFormat);
    }
    return i;
  });

interface TimeSlots {
  isBaseTimeFormat: boolean;
  days: string[];
  hoursInDays: HoursInDayDiffTime[];
  months: string[];
  numberOfDays: number;
  numberOfHoursInDay: number;
  numberOfMonths: number;
  offsetStartHoursRange: number;
  weekDayWidth?: number;
}

export const generateTimelineSlots = (
  modeType: Mode["type"],
  options: TimeSlots
) => {
  const { hoursInDays, ...rest } = options;
  if (modeType === "week") {
    return generateWeekTimelineSlots(rest) as string[];
  }

  if (modeType === "month") {
    return generateMonthTimelineSlots(rest) as string[];
  }

  if (hoursInDays.length > 0) {
    return getDayHoursTimeSlots(options) as (string | number)[];
  }

  return getDayTimeSlots(rest) as (string | number)[];
};

const getDayHoursTimeSlots = ({
  isBaseTimeFormat,
  days,
  hoursInDays,
  numberOfDays,
}: Pick<
  TimeSlots,
  "isBaseTimeFormat" | "days" | "hoursInDays" | "numberOfDays"
>) => {
  const slots = generateArray(numberOfDays).reduce((acc, _, dayIndex) => {
    const hours = generateDayHours(isBaseTimeFormat, dayIndex, days);
    const hoursInDay = hoursInDays[dayIndex];
    const sliced = hours.slice(hoursInDay.startTime, hoursInDay.endTime);
    if (dayIndex > 0) sliced[0] = hours[0];
    return [...acc, ...sliced];
  }, []);
  return slots;
};

const getDayTimeSlots = ({
  isBaseTimeFormat,
  days,
  numberOfDays,
  numberOfHoursInDay,
  offsetStartHoursRange,
}: Omit<TimeSlots, "hoursInDays">) => {
  const slots = generateArray(numberOfDays).reduce((acc, _, dayIndex) => {
    const hours = generateDayHours(isBaseTimeFormat, dayIndex, days);
    if (dayIndex === 0) return [...acc, ...hours.slice(offsetStartHoursRange)];
    return [...acc, ...hours];
  }, []);
  return slots.slice(0, numberOfHoursInDay);
};

export const generateWeekTimelineSlots = ({
  days,
  numberOfDays,
}: Pick<TimeSlots, "days" | "numberOfDays">) => {
  const slots = generateArray(numberOfDays).map((_, dayIndex) => {
    const date = parse(days[dayIndex], TIME_FORMAT.DATE, new Date());
    return format(date, TIME_FORMAT.DATE);
  });

  return slots;
};
export const generateMonthTimelineSlots = ({
  months,
  numberOfMonths,
}: Pick<TimeSlots, "isBaseTimeFormat" | "months" | "numberOfMonths">) => {
  const slots = generateArray(numberOfMonths).map((_, dayIndex) => {
    return format(new Date(months[dayIndex]), TIME_FORMAT.DATE);
  });

  return slots;
};

interface TimelineMonthsWidth {
  data: { width: number; left: number }[];
  offsetLeft: number;
}
export const getTimelineMonthsWidth = ({
  months,
  weekDayWidth,
}: Pick<TimeSlots, "months" | "weekDayWidth">) => {
  const { data } = months.reduce(
    (acc, month, index) => {
      const daysInMonth = getDaysInMonth(new Date(month));
      const width = daysInMonth * (weekDayWidth as number);
      if (index === 0) {
        acc.data.push({ width, left: 0 });
        acc.offsetLeft = 0;
        return acc;
      }
      const left = acc.offsetLeft + acc.data[index - 1].width;
      acc.data.push({ width, left });
      acc.offsetLeft = left;
      return acc;
    },
    { data: [], offsetLeft: 0 } as TimelineMonthsWidth
  );

  return data;
};

export const getTimelineHeight = (timelineHeight: number, mode: Mode) => {
  if (
    (mode.type === "week" || mode.type === "month") &&
    mode.style === "modern"
  ) {
    return TIMELINE_HEIGHT_MODERN_STYLE;
  }
  return timelineHeight;
};
