import { ProgramItem } from "./types";
import { ProgramOverlaps } from "./interfaces";
import { switchPosition } from "./common";
import { getChannelEpgIndexes } from "./layout";

function getOverlapCount(
  program: ProgramItem,
  overlapArray: ProgramItem[]
): number {
  const sinceB = new Date(program.data.since);
  const tillB = new Date(program.data.till);

  let overlapCount = 0;

  for (const otherProgram of overlapArray) {
    if (program.data.id === otherProgram.data.id) continue;

    const sinceOther = new Date(otherProgram.data.since);
    const tillOther = new Date(otherProgram.data.till);

    if (
      (sinceB <= tillOther && tillB >= sinceOther) ||
      (sinceOther <= tillB && tillOther >= sinceB)
    ) {
      overlapCount++;
    }
  }

  return overlapCount;
}

function overlapPosition(
  program: ProgramItem,
  overlapArray: ProgramItem[],
  channelOverlapsCount: number
) {
  const sinceB = new Date(program.data.since);
  const tillB = new Date(program.data.till);
  const newChannelOverlapsCount = channelOverlapsCount;

  for (let index = 0; index < newChannelOverlapsCount + 1; index++) {
    const top =
      program.data.channelPosition.top > 0
        ? program.data.channelPosition.top
        : 0;
    let levelTop = top + program.position.height * index;
    let overlapsOnLevel = false;
    let overlapsOnLevelUp = false;

    for (const otherProgram of overlapArray) {
      if (program.data.id === otherProgram.data.id) continue;

      if (levelTop === otherProgram.position.top) {
        const sinceOther = new Date(otherProgram.data.since);
        const tillOther = new Date(otherProgram.data.till);

        if (
          (sinceB < tillOther && tillB > sinceOther) ||
          (sinceOther < tillB && tillOther > sinceB)
        ) {
          if (
            (sinceB <= tillOther && tillB >= sinceOther) ||
            (sinceOther <= tillB && tillOther >= sinceB)
          ) {
            overlapsOnLevelUp = true;
          }
          overlapsOnLevel = true;
          break;
        }
      }
    }

    if (!overlapsOnLevel) {
      return overlapsOnLevelUp ? index + 4 : index;
    }
  }
  return newChannelOverlapsCount;
}

const getOverlapProgramOptions = (
  program: ProgramItem,
  overlapArray: ProgramItem[],
  channelOverlapsCount: number
) => {
  const _program = { ...program };

  const { data, position } = _program;

  const programOverlapIndex = overlapArray.length + 1;

  const isOverlapStackMode = true;
  const programOverlapsLength = overlapArray.length;
  const { channelPosition, overlapTimes } = data;
  const { width, height, left } = position;
  const newHeight = isOverlapStackMode
    ? height
    : height / programOverlapsLength;

  const newTop =
    channelPosition.top + (overlapTimes ?? programOverlapIndex) * newHeight;

  const newProgram = {
    ...program,
    position: { width, height: newHeight, top: newTop, left },
  };

  const overlapTop = overlapPosition(
    newProgram,
    overlapArray,
    channelOverlapsCount
  );

  newProgram.position = {
    ...newProgram.position,
    top: channelPosition.top + newProgram.position.height * overlapTop,
  };

  return [newProgram, overlapTop + 1];
};

const setChannelOverlapCount = (
  programA: ProgramItem,
  channelOverlapsCount: number,
  channelOverlaps: Record<string, number>
) =>
  channelOverlapsCount > channelOverlaps[programA.data.channelUuid]
    ? channelOverlapsCount
    : channelOverlaps[programA.data.channelUuid];

export function getOverlaps(
  isVerticalMode: boolean,
  itemOverlaps: ProgramOverlaps,
  programs: ProgramItem[]
): {
  overlaps: ProgramOverlaps;
  channelOverlaps: Record<string, number>;
} {
  const overlaps: ProgramOverlaps = { ...itemOverlaps };
  const channelOverlaps: Record<string, number> = {};

  for (const program of programs) {
    overlaps[program.data.channelUuid] = [];
  }

  for (let i = 0; i < programs.length - 1; i++) {
    const programA = { ...programs[i] };
    const sinceA = new Date(programA.data.since);
    const tillA = new Date(programA.data.till);

    if (isVerticalMode) {
      programA.position = switchPosition(programA.position);
    }

    for (let j = i + 1; j < programs.length; j++) {
      const programB = { ...programs[j] };

      if (programA.data.channelUuid !== programB.data.channelUuid) continue;

      if (isVerticalMode) {
        programB.position = switchPosition(programB.position);
      }

      const sinceB = new Date(programB.data.since);
      const tillB = new Date(programB.data.till);

      if (
        (sinceA < tillB && tillA > sinceB) ||
        (sinceB < tillA && tillB > sinceA)
      ) {
        const elementA = overlaps[programA.data.channelUuid];
        const elementB = overlaps[programB.data.channelUuid];

        const isSameChannelUuid =
          programA.data.channelUuid === programB.data.channelUuid;

        if (elementA && elementB && isSameChannelUuid) {
          programB.data.overlapLinkedId = programA.data.overlapLinkedId;

          const overlapArray = overlaps[programA.data.channelUuid];

          const isElementAExist = overlapArray.some(
            (el) => el.data.id === programA.data.id
          );
          const isElementBExist = overlapArray.some(
            (el) => el.data.id === programB.data.id
          );
          const channelOverlapsLength =
            overlaps[programA.data.channelUuid].length;

          if (!isElementBExist && channelOverlapsLength === 0) {
            programB.position.top =
              programA.data.channelPosition.top + programA.position.height;
            overlaps[programA.data.channelUuid] = [programA, programB];
            channelOverlaps[programA.data.channelUuid] = !channelOverlaps[
              programA.data.channelUuid
            ]
              ? 2
              : channelOverlaps[programA.data.channelUuid];
          } else if (!isElementBExist && channelOverlapsLength > 1) {
            let overlapCount = 0;

            if (!isElementAExist) {
              overlapCount = getOverlapCount(programB, overlapArray);
              programB.data.overlapTimes = overlapCount;

              const [newProgramA, channelOverlapsCount] =
                getOverlapProgramOptions(
                  programA,
                  overlapArray,
                  channelOverlaps[programA.data.channelUuid]
                ) as [ProgramItem, number];

              overlapArray.push(newProgramA);

              channelOverlaps[programA.data.channelUuid] =
                setChannelOverlapCount(
                  programA,
                  channelOverlapsCount,
                  channelOverlaps
                );
            }

            overlapCount = getOverlapCount(programB, overlapArray);
            programB.data.overlapTimes = overlapCount;

            const [newProgramB, channelOverlapsCount] =
              getOverlapProgramOptions(
                programB,
                overlapArray,
                channelOverlaps[programA.data.channelUuid]
              ) as [ProgramItem, number];
            overlapArray.push(newProgramB);

            channelOverlaps[programA.data.channelUuid] = setChannelOverlapCount(
              programA,
              channelOverlapsCount,
              channelOverlaps
            );
          } else if (isElementBExist && channelOverlapsLength > 1) {
            const overlapCount = getOverlapCount(programB, overlapArray);
            programB.data.overlapTimes = overlapCount;

            const [, channelOverlapsCount] = getOverlapProgramOptions(
              programB,
              overlapArray,
              channelOverlaps[programA.data.channelUuid]
            ) as [ProgramItem, number];

            channelOverlaps[programA.data.channelUuid] = setChannelOverlapCount(
              programA,
              channelOverlapsCount,
              channelOverlaps
            );
          } else if (channelOverlapsLength > 1) {
            overlapArray.push(programB);
          }
        }
      } else if (
        overlaps[programA.data.channelUuid] &&
        overlaps[programA.data.channelUuid].length === 0
      ) {
        channelOverlaps[programA.data.channelUuid] = 0;
        overlaps[programA.data.channelUuid] = [];
      }
    }
  }

  return { overlaps, channelOverlaps };
}

const getChannelProgramSliced = (programs: ProgramItem[]) => {
  const acc = new Map();

  for (const next of programs) {
    const channelUuid = next.data.channelUuid;
    if (!acc.has(channelUuid)) {
      acc.set(channelUuid, []);
    }
    acc.get(channelUuid).push(next);
  }

  return Object.fromEntries(acc) as Record<string, ProgramItem[]>;
};

const getChannelEpdIndexesProgramSliced = (
  programs: ProgramItem[],
  overlaps: ProgramOverlaps,
  dndChannelUuidIndex: number
) => {
  const acc = {} as Record<string, ProgramItem[]>;

  for (const next of Object.keys(overlaps)) {
    const channelIndex = overlaps[next]?.[0]?.data.channelIndex;
    const channelUuid = overlaps[next]?.[0]?.data.channelUuid;
    if (channelIndex !== undefined && channelIndex > dndChannelUuidIndex) {
      const channelEpgIndexes = getChannelEpgIndexes(next);
      const slicedPrograms = programs.slice(
        channelEpgIndexes.first,
        channelEpgIndexes.last + 1
      );
      acc[channelUuid] = slicedPrograms;
    }
  }
  return acc;
};

export function checkOverlaps(
  isMultirowsDnd: boolean,
  isVerticalMode: boolean,
  dndChannelUuid: { index: number; uuid: string },
  itemOverlaps: ProgramOverlaps,
  programs: ProgramItem[]
) {
  const overlaps: ProgramOverlaps = { ...itemOverlaps };
  const channelOverlaps: Record<string, number> = {};

  let data = {} as Record<string, ProgramItem[]>;

  const channelEpgIndexes = getChannelEpgIndexes(dndChannelUuid.uuid);
  if (!isMultirowsDnd && channelEpgIndexes) {
    const slicedOverlapsPrograms = getChannelEpdIndexesProgramSliced(
      programs,
      overlaps,
      dndChannelUuid.index
    );
    data = {
      [dndChannelUuid.uuid]: programs.slice(
        channelEpgIndexes.first,
        channelEpgIndexes.last + 1
      ),
      ...slicedOverlapsPrograms,
    };
  } else {
    data = getChannelProgramSliced(programs);
  }

  for (const channel in data) {
    const props = getOverlaps(isVerticalMode, itemOverlaps, data[channel]);
    overlaps[channel] = props.overlaps[channel];
    channelOverlaps[channel] = props.channelOverlaps[channel];
  }
  for (const channelUuid in overlaps) {
    if (
      overlaps?.[channelUuid] &&
      Object.keys(overlaps?.[channelUuid])?.length === 0
    ) {
      delete overlaps[channelUuid];
    }
  }

  return { overlaps, channelOverlaps };
}
