import * as React from "react";
// Import interfaces
import { Mode, Grid as IGrid, HoursInDayDiffTime } from "../helpers/interfaces";
// Import types
import {
  BaseTimeFormat,
  ChannelWithPosition,
  Position,
} from "../helpers/types";
// Import helpers
import { generateArray } from "../helpers";
// Import styles
import { GridStyled } from "../styles";
// Import hooks
import { useGrid } from "../hooks";

const { GridItem, GridDivider } = GridStyled;

interface GridProps {
  isVerticalMode: boolean;
  isProgramVisible: (position: Position, overlapsCount: number) => boolean;
  isBaseTimeFormat: BaseTimeFormat;
  channelOverlapsCount: Record<string, number>;
  hourWidth: number;
  dayWidth: number;
  days: string[];
  hoursInDays: HoursInDayDiffTime[];
  months: string[];
  numberOfDays: number;
  numberOfHoursInDay: number;
  numberOfMonths: number;
  offsetStartHoursRange: number;
  gridItems: { position: Position; channel: ChannelWithPosition }[];
  grid: IGrid;
  mode: Mode;
  sidebarWidth: number;
  timelineHeight: number;
}

export function Grid({
  isVerticalMode,
  isProgramVisible,
  channelOverlapsCount,
  grid,
  gridItems,
  mode,
  hourWidth,
  ...rest
}: GridProps) {
  const { onItemClick } = useGrid({
    isVerticalMode,
    grid,
    mode,
    hourWidth,
    ...rest,
  });
  const isDayMode = mode.type === "day";
  const isHoverHighlight = grid.hoverHighlight;
  const isItemClickable = !!grid.onGridItemClick;

  const renderGridItem = (
    item: { position: Position; channel: ChannelWithPosition },
    index: number
  ) => {
    const overlapsCount = channelOverlapsCount[item.channel.uuid] ?? 1;
    const isVisible = isProgramVisible(item.position, overlapsCount);
    if (!isVisible) return null;
    const gridItemClickProps = isDayMode
      ? { isItemClickable: false }
      : { onClick: onItemClick(item), isItemClickable };
    return (
      <GridItem
        key={item.channel.uuid + index}
        isDayMode={isDayMode}
        isHoverHighlight={isHoverHighlight as boolean}
        {...item.position}
        {...gridItemClickProps}
      >
        {isDayMode &&
          generateArray(4).map((_, index) => (
            <GridDivider
              key={index}
              isVerticalMode={isVerticalMode}
              isHoverHighlight={isHoverHighlight as boolean}
              isItemClickable={isItemClickable}
              left={index * (hourWidth / 4)}
              width={hourWidth / 4}
              onClick={onItemClick(item, index)}
            />
          ))}
      </GridItem>
    );
  };

  return <>{gridItems.map((item, index) => renderGridItem(item, index))}</>;
}
