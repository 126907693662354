import styled from "@emotion/styled/macro";
import { Layers, Theme } from "../helpers";

export const Container = styled.div<{
  height?: number;
  width?: number;
}>`
  padding: 5px;
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
`;

export const ScrollBox = styled.div<{ theme?: Theme; isRTL?: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  background: ${({ theme }) => theme.primary[900]};

  ${({ isRTL }) => isRTL && `transform: scale(-1,1)`};

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.scrollbar.thumb.bg};
    border: 10px none ${({ theme }) => theme.white};
    border-radius: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.white};
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.primary[900]};
    border: 22px none ${({ theme }) => theme.white};
    border-radius: 0px;
  }

  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.primary[900]};
  }
`;

export const Box = styled.div<{
  isRTL?: boolean;
  left?: number;
  top?: number;
  theme?: Theme;
}>`
  position: absolute;
  top: ${({ top = 0 }) => top}px;
  background: ${({ theme }) => theme.primary[900]};
  z-index: ${Layers.EpgCornerBox};

  ${({ isRTL, left = 0 }) => (isRTL ? `right:0px;` : ` left: ${left}px`)};
`;

export const Content = styled.div<{
  isVerticalMode: boolean;
  isSidebar: boolean;
  isTimeline: boolean;
  theme?: Theme;
  dayWidth: number;
  contentHeight: number;
  sidebarWidth: number;
  timelineHeight: number;
}>`
  position: relative;
  left: ${({ isSidebar, sidebarWidth }) => (isSidebar ? sidebarWidth : 0)}px;
  height: ${({ contentHeight }) => contentHeight}px;
  width: ${({ dayWidth }) => dayWidth}px;
  background: ${({ theme }) => theme.primary[900]};

  ${({ isVerticalMode, isTimeline, dayWidth, timelineHeight, contentHeight }) =>
    isVerticalMode &&
    `
      left: ${isTimeline ? timelineHeight : 0}px;
      width: ${contentHeight}px;
      height: ${dayWidth}px;
    `}
`;

export const LayoutBg = styled.div<{
  isVerticalMode: boolean;
  isTimeline: boolean;
  isSidebar: boolean;
  dayWidth: number;
  contentHeight: number;
  timelineHeight: number;
  sidebarWidth: number;
}>`
  position: absolute;
  top: ${({ isTimeline, timelineHeight }) =>
    isTimeline ? timelineHeight : 0}px;
  left: ${({ isSidebar, sidebarWidth }) => (isSidebar ? sidebarWidth : 0)}px;
  height: 100%;
  width: ${({ dayWidth }) => dayWidth}px;

  ${({
    isVerticalMode,
    isTimeline,
    isSidebar,
    dayWidth,
    timelineHeight,
    sidebarWidth,
  }) =>
    isVerticalMode &&
    `
     top: ${isSidebar ? sidebarWidth : 0}px;
     left: ${isTimeline ? timelineHeight : 0}px;
     width: 100%;
     height: ${dayWidth}px;
    `}
`;
