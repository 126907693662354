import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";

export const GridWrapper = styled.div<{
  isVerticalMode: boolean;
  isSidebar: boolean;
  isTimeline: boolean;
  sidebarWidth: number;
  timelineHeight: number;
}>`
  position: relative;
  top: ${({ isTimeline, timelineHeight }) =>
    isTimeline ? timelineHeight : 0}px;
  left: ${({ isSidebar, sidebarWidth }) => (isSidebar ? sidebarWidth : 0)}px;

  ${({ isVerticalMode, isSidebar, isTimeline, sidebarWidth, timelineHeight }) =>
    isVerticalMode &&
    `
     top: ${isSidebar ? sidebarWidth : 0}px;
      left: ${isTimeline ? timelineHeight : 0}px;
    `}
`;

export const GridItem = styled.div<{
  isDayMode: boolean;
  isHoverHighlight: boolean;
  isItemClickable: boolean;
  top: number;
  left: number;
  height: number;
  width: number;
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  border: 1px solid transparent;
  border-right-color: ${({ theme }) => theme.grid.item};
  border-bottom-color: ${({ theme }) => theme.grid.item};
  z-index: 1;

  ${({ isDayMode, isHoverHighlight, theme }) =>
    !isDayMode &&
    isHoverHighlight &&
    `
    &:hover {
      border: 1px solid ${theme.grid.highlight};
    }   
    `}

  ${({ isItemClickable }) =>
    isItemClickable &&
    css`
      cursor: pointer;
    `}
`;

export const GridDivider = styled.div<{
  isVerticalMode?: boolean;
  isHoverHighlight: boolean;
  isItemClickable?: boolean;
  left: number;
  width: number;
}>`
  position: absolute;
  top: 0;
  left: ${({ left }) => left}px;
  border: 1px solid transparent;
  border-right-color: ${({ theme }) => theme.grid.divider};
  height: 100%;
  width: ${({ width }) => width}px;

  ${({ isHoverHighlight, theme }) =>
    isHoverHighlight &&
    `
    &:hover {
      border: 1px solid ${theme.grid.highlight};
    }   
    `}

  ${({ isVerticalMode, left, width, theme }) =>
    isVerticalMode &&
    css`
      top: ${left}px;
      left: 0;
      width: 100%;
      height: ${width}px;
      border-bottom-color: ${theme.grid.divider};
    `}

  ${({ isItemClickable }) =>
    isItemClickable &&
    css`
      cursor: pointer;
    `}
`;
