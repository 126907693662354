import styled from "@emotion/styled/macro";
// Import helpers
import { Layers, Theme } from "../helpers";
import { css } from "@emotion/react";

export const AreaFiled = styled.div<{
  isVerticalMode?: boolean;
  isClickable?: boolean;
  positionX: number;
  height: number;
  width: number;
  timelineHeight: number;
  theme?: Theme;
}>`
  position: absolute;
  pointer-events: ${(props) => (props.isClickable ? "auto" : "none")};
  cursor: ${(props) => (props.isClickable ? "pointer" : "none")};
  z-index: ${Layers.Area};

  ${({ isVerticalMode, positionX, height, width, timelineHeight }) =>
    isVerticalMode
      ? css`
          top: ${positionX}px;
          left: ${timelineHeight}px;
          height: ${width}px;
          width: ${height}px;
        `
      : css`
          top: ${timelineHeight}px;
          left: ${positionX}px;
          height: ${height}px;
          width: ${width}px;
        `}
`;
export const AreaBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AreaAnnotation = styled.div<{
  isVerticalMode: boolean;
  isLeft?: boolean;
  width: number;
  timelineHeight: number;
}>`
  padding: 4px 6px;
  font-size: 15px;
  width: max-content;

  ${({ isVerticalMode, isLeft = false, width, timelineHeight }) => css`
    ${!isVerticalMode
      ? css`
          position: sticky;
          top: ${timelineHeight}px;
          ${isLeft
            ? `float:left; border-top-right-radius: 4px; border-bottom-right-radius:4px;`
            : `float:right; border-top-left-radius: 4px; border-bottom-left-radius: 4px;`}
        `
      : css`
          position: sticky;
          left: ${timelineHeight}px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          ${isLeft && css`` ? `top: 0; ` : `margin-top:${width - 60}px;`}
        `}
  `}
`;
