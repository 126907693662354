import { isWithinInterval } from "date-fns";
import { getPositionX } from "./epg";
import { Area } from "./interfaces";
import { DateTime } from "./types";

interface AreasData {
  area: Area;
  hourWidth: number;
  sidebarWidth: number;
  timelineHeight: number;
  height: number;
  startDate: DateTime;
  endDate: DateTime;
}

export const getAreasFields = ({
  area,
  startDate,
  endDate,
  hourWidth,
  sidebarWidth,
  timelineHeight,
  height,
}: AreasData) => {
  const areaStartDate = area.startDate;
  const areaEndDate = area?.endDate ?? areaStartDate;

  const left =
    getPositionX(startDate, areaStartDate, startDate, endDate, hourWidth) +
    sidebarWidth;

  const width = getPositionX(
    areaStartDate,
    areaEndDate,
    startDate,
    endDate,
    hourWidth
  );

  const intervalOptions = {
    start: new Date(startDate),
    end: new Date(endDate),
  };
  const isStartAreaDateValid = isWithinInterval(
    new Date(areaStartDate),
    intervalOptions
  );

  const showArea = isStartAreaDateValid;
  const areaFieldStyles = {
    positionX: left,
    width,
    height,
    timelineHeight,
  };
  return {
    ...area,
    showArea,
    areaFieldStyles,
    areaBgStyles: area.styles,
  };
};
