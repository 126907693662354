import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Theme } from "../helpers";

export const ChannelBox = styled.div<{
  isVerticalMode?: boolean;
  top: number;
  height: number;
  theme?: Theme;
}>`
  ${({ isVerticalMode, top, height, theme }) => css`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.primary[900]};

    ${isVerticalMode &&
    css`
      top: 0;
      left: ${top}px;
      width: ${height}px;
      height: 100%;
    `}
    ${!isVerticalMode &&
    css`
      top: ${top}px;
      height: ${height}px;
      width: 100%;
    `};
  `}
`;

export const ChannelLogo = styled.img`
  max-height: 60px;
  max-width: 60px;
  position: relative;
`;
