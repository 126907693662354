import React from "react";
import { ThemeProvider, Global } from "@emotion/react";

// Import interfaces
import { Theme } from "./helpers/interfaces";

// Import styles
import { globalStyles, EpgStyled } from "./styles";

// Import components
import { Loader } from "./components";

interface EpgProps {
  width?: number;
  height?: number;
  isVerticalMode?: boolean;
  isRTL?: boolean;
  isSidebar: boolean;
  isTimeline?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
  loader?: React.ReactNode;
  theme: Theme;
  globalStyles?: string;
  sidebarWidth: number;
  timelineHeight: number;
}

const { Container, Wrapper, Box } = EpgStyled;

export const Epg = React.forwardRef<HTMLDivElement, EpgProps>(
  (
    {
      children,
      width,
      height,
      sidebarWidth,
      timelineHeight,
      theme,
      globalStyles: customGlobalStyles,
      isVerticalMode = false,
      isRTL = false,
      isSidebar = true,
      isTimeline = true,
      isLoading = false,
      loader: LoaderComponent,
      ...rest
    },
    containerRef
  ) => {
    const renderLoader = () => LoaderComponent ?? <Loader />;
    const epgGlobalStyles = customGlobalStyles ?? globalStyles;

    const blankSpaceStyles = isVerticalMode
      ? { height: sidebarWidth, width: timelineHeight }
      : { width: sidebarWidth, height: timelineHeight };

    return (
      <ThemeProvider theme={theme}>
        <Global styles={epgGlobalStyles} />
        <Container
          className="planby"
          data-testid="container"
          width={width}
          height={height}
          ref={containerRef}
          {...rest}
        >
          <Wrapper id="planby-wrapper">
            {isSidebar && isTimeline && (
              <Box isRTL={isRTL} left={0} top={0} style={blankSpaceStyles} />
            )}
            {isLoading && renderLoader()}
            {children}
          </Wrapper>
        </Container>
      </ThemeProvider>
    );
  }
);
