import { Layers } from "./enums";

interface LineStyles {
  isVerticalMode: boolean;
  isTimeline: boolean;
  lineStyles: {
    position: string;
    top: number;
    width: number;
  };
  position: {
    height: number;
    left: number;
    zIndex: Layers;
  };
}
export const getLineStyles = ({
  isVerticalMode,
  isTimeline,
  lineStyles,
  position,
}: LineStyles) => {
  if (isVerticalMode) {
    return {
      position: {
        ...position,
        position: lineStyles.position,
        top: position.left,
        left: isTimeline ? lineStyles.top : 0,
        width: position.height,
        height: lineStyles.width,
      },
    };
  }

  return {
    position: {
      ...position,
      position: lineStyles.position,
      top: isTimeline ? lineStyles.top : 0,
      width: lineStyles.width,
    },
  };
};
