import React from "react";

// Import interfaces
import {
  Area,
  Grid as IGrid,
  HoursInDayDiffTime,
  Line as ILine,
  Mode,
  Overlap,
  ProgramOverlaps,
  DragAndDrop,
} from "../helpers/interfaces";

// Import types
import {
  Timeline as ITimeline,
  CurrentTimeIndicator,
} from "../helpers/interfaces";
import {
  ProgramItem,
  ProgramWithPosition,
  ChannelWithPosition,
  DateTime,
  Position,
  BaseTimeFormat,
  DragMouseUp,
} from "../helpers/types";

// Import helpers
import {
  deleteLayoutScreenCloneElement,
  getChannelsContentHeight,
  getProgramOptions,
  isFutureTime,
  setLayoutScreenCloneElement,
} from "../helpers";

// Import styles
import { EpgStyled } from "../styles";

// Import components
import { Areas, Channels, Line, Program, Timeline } from "../components";
import { LayoutBg } from "../styles/Epg.styles";
import { Grid } from "./Grid";

interface LayoutProps {
  isToday: boolean;
  isVerticalMode?: boolean;
  isRTL?: boolean;
  isBaseTimeFormat?: BaseTimeFormat;
  isSidebar?: boolean;
  isTimeline?: boolean;
  isLine?: boolean;
  isCurrentTime?: boolean;
  isResize?: boolean;
  programs: ProgramItem[];
  programOverlaps: ProgramOverlaps;
  layerOverlapLevel: number;
  channels: ChannelWithPosition[];
  channelOverlapsCount: Record<string, number>;
  startDate: DateTime;
  endDate: DateTime;
  hoursInDays: HoursInDayDiffTime[];
  scrollY: number;
  dayWidth: number;
  hourWidth: number;
  days: string[];
  months: string[];
  currentDate: string;
  numberOfDays: number;
  numberOfHoursInDay: number;
  numberOfMonths: number;
  monthWidth: number;
  offsetStartHoursRange: number;
  sidebarWidth: number;
  timelineHeight: number;
  itemHeight: number;
  liveRefreshTime: number;
  mode: Mode;
  dnd: DragAndDrop;
  overlapMode: Overlap["mode"];
  areas: Area[];
  gridItems: { position: Position; channel: ChannelWithPosition }[];
  grid: IGrid;
  overlap: Overlap;
  isProgramVisible: (position: Position, overlapsCount: number) => boolean;
  isChannelVisible: (position: Pick<Position, "top" | "height">) => boolean;
  isTimelineVisible: (position: Pick<Position, "left" | "width">) => boolean;
  dragMouseUp: (position: DragMouseUp) => void;
  resizeMouseUp: (data: any) => void;
  onScroll: (
    e: React.UIEvent<HTMLDivElement, UIEvent> & { target: Element }
  ) => void;
  onLayoutBgClick?: () => void;
  renderProgram?: (v: {
    program: ProgramItem;
    isRTL: boolean;
    isVerticalMode: boolean;
    isBaseTimeFormat: BaseTimeFormat;
    liveRefreshTime: number;
    dayWidth: number;
    hourWidth: number;
    itemHeight: number;
    contentHeight: number;
    mode: Mode;
    dnd: DragAndDrop;
    dragMouseUp: (data: DragMouseUp) => void;
    resizeMouseUp: (data: any) => void;
  }) => React.ReactNode;
  renderChannel?: (v: { channel: ChannelWithPosition }) => React.ReactNode;
  renderTimeline?: (v: ITimeline) => React.ReactNode;
  renderLine?: (v: ILine) => React.ReactNode;
  renderCurrentTime?: (v: CurrentTimeIndicator) => React.ReactElement;
}

const { ScrollBox, Content } = EpgStyled;

export const Layout = React.forwardRef<HTMLDivElement, LayoutProps>(
  (props, scrollBoxRef) => {
    const {
      areas,
      grid,
      gridItems,
      mode,
      dnd,
      overlap,
      overlapMode,
      channels,
      channelOverlapsCount,
      programs,
      programOverlaps,
      layerOverlapLevel,
      startDate,
      endDate,
      hoursInDays,
      liveRefreshTime,
      scrollY,
    } = props;

    const {
      dayWidth,
      hourWidth,
      monthWidth,
      sidebarWidth,
      timelineHeight,
      itemHeight,
    } = props;

    const {
      days,
      months,
      numberOfHoursInDay,
      numberOfDays,
      numberOfMonths,
      offsetStartHoursRange,
    } = props;

    const {
      isVerticalMode = false,
      isRTL = false,
      isSidebar = true,
      isTimeline = true,
      isLine = true,
      isToday,
      isBaseTimeFormat = false,
      isCurrentTime = false,
      isResize = false,
    } = props;

    const {
      dragMouseUp,
      resizeMouseUp,
      onLayoutBgClick,
      onScroll,
      isProgramVisible,
      isChannelVisible,
      isTimelineVisible,
      renderProgram,
      renderChannel,
      renderTimeline,
      renderLine,
      renderCurrentTime,
    } = props;

    // Calculate the height of the content
    const channelsLength = channels.length;
    const programsOverlapsLength = Object.keys(programOverlaps).length;
    const programOverlapsSerialized = JSON.stringify(programOverlaps);
    const contentHeight = React.useMemo(
      () => {
        if (programsOverlapsLength > 0) {
          return getChannelsContentHeight(channels);
        }
        return channelsLength * itemHeight;
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        channelsLength,
        programsOverlapsLength,
        itemHeight,
        programOverlapsSerialized,
      ]
    );

    const showAreas = areas.length > 0;
    const isFuture = isFutureTime(endDate);

    const renderPrograms = (program: ProgramWithPosition, index: number) => {
      const { position } = program;
      const overlapsCount = channelOverlapsCount[program.data.channelUuid] ?? 1;
      const isVisible = isProgramVisible(position, overlapsCount);

      if (isVisible) {
        setLayoutScreenCloneElement(program, index);
        const options = getProgramOptions({
          isVerticalMode,
          program,
          programOverlaps,
          layerOverlapLevel,
          overlapMode,
          overlap,
        });
        const props = {
          isRTL,
          isResize,
          isVerticalMode,
          isBaseTimeFormat,
          program: options,
          liveRefreshTime,
          mode,
          dnd,
          dayWidth,
          contentHeight,
          itemHeight,
          hourWidth,
          dragMouseUp,
          resizeMouseUp,
        };
        if (renderProgram) return renderProgram(props);

        return (
          <Program
            key={`${program.data.channelUuid}-${program.data.id}`}
            {...props}
          />
        );
      }
      deleteLayoutScreenCloneElement(program);
      return null;
    };

    const renderTopbar = () => {
      const timeProps = {
        isVerticalMode,
        isSidebar,
        isRTL,
        isTimelineVisible,
        isToday,
        isBaseTimeFormat,
        isCurrentTime,
        startDate,
        endDate,
        dayWidth,
        sidebarWidth,
        timelineHeight,
        offsetStartHoursRange,
        monthWidth,
        numberOfHoursInDay,
        numberOfMonths,
        numberOfDays,
        hourWidth,
        days,
        months,
        hoursInDays,
        liveRefreshTime,
        mode,
        renderCurrentTime,
      };
      if (renderTimeline) {
        return renderTimeline(timeProps);
      }
      return <Timeline {...timeProps} />;
    };

    return (
      <ScrollBox
        ref={scrollBoxRef}
        id="planby-layout-scrollbox"
        className="planby-layout"
        isRTL={isRTL}
        onScroll={onScroll}
      >
        {grid.enabled && (
          <Grid
            isVerticalMode={isVerticalMode}
            isProgramVisible={isProgramVisible}
            channelOverlapsCount={channelOverlapsCount}
            isBaseTimeFormat={isBaseTimeFormat}
            hourWidth={hourWidth}
            dayWidth={dayWidth}
            days={days}
            hoursInDays={hoursInDays}
            months={months}
            numberOfDays={numberOfDays}
            numberOfHoursInDay={numberOfHoursInDay}
            numberOfMonths={numberOfMonths}
            offsetStartHoursRange={offsetStartHoursRange}
            grid={grid}
            gridItems={gridItems}
            mode={mode}
            sidebarWidth={sidebarWidth}
            timelineHeight={timelineHeight}
          />
        )}
        {onLayoutBgClick && (
          <LayoutBg
            className="planby-layout-bg"
            isSidebar={isSidebar}
            isVerticalMode={isVerticalMode}
            isTimeline={isTimeline}
            dayWidth={dayWidth}
            contentHeight={contentHeight}
            sidebarWidth={sidebarWidth}
            timelineHeight={timelineHeight}
            onClick={onLayoutBgClick}
          />
        )}
        {isLine && isToday && isFuture && (
          <Line
            isVerticalMode={isVerticalMode}
            isTimeline={isTimeline}
            dayWidth={dayWidth}
            hourWidth={hourWidth}
            sidebarWidth={sidebarWidth}
            startDate={startDate}
            endDate={endDate}
            height={contentHeight}
            liveRefreshTime={liveRefreshTime}
            hoursInDays={hoursInDays}
            renderLine={renderLine}
          />
        )}

        {showAreas && (
          <Areas
            isVerticalMode={isVerticalMode}
            sidebarWidth={sidebarWidth}
            hourWidth={hourWidth}
            areas={areas}
            startDate={startDate}
            endDate={endDate}
            height={contentHeight}
            timelineHeight={timelineHeight}
          />
        )}
        {isTimeline && renderTopbar()}
        {isSidebar && (
          <Channels
            isVerticalMode={isVerticalMode}
            isRTL={isRTL}
            isTimeline={isTimeline}
            isChannelVisible={isChannelVisible}
            timelineHeight={timelineHeight}
            sidebarWidth={sidebarWidth}
            contentHeight={contentHeight}
            channels={channels as ChannelWithPosition[]}
            scrollY={scrollY}
            renderChannel={renderChannel}
          />
        )}

        <Content
          className="planby-content"
          data-testid="content"
          isVerticalMode={isVerticalMode}
          isSidebar={isSidebar}
          isTimeline={isTimeline}
          dayWidth={dayWidth}
          sidebarWidth={sidebarWidth}
          timelineHeight={timelineHeight}
          contentHeight={contentHeight}
        >
          {programs.map((program, index) =>
            renderPrograms(program as ProgramWithPosition, index)
          )}
        </Content>
      </ScrollBox>
    );
  }
);
