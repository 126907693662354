import * as React from "react";

// Import types
import {
  CurrentTimeIndicator,
  HoursInDayDiffTime,
  Mode,
} from "../helpers/interfaces";
import { DateTime } from "../helpers/types";

// Import styles
import { CurrentTimeStyled } from "../styles";

// Import hooks
import { useLine, useCurrentTime } from "../hooks";

interface CurrentTimeProps {
  isBaseTimeFormat?: boolean;
  isVerticalMode?: boolean;
  isRTL?: boolean;
  mode: Mode;
  startDate: DateTime;
  endDate: DateTime;
  hoursInDays: HoursInDayDiffTime[];
  dayWidth: number;
  timelineHeight: number;
  hourWidth: number;
  sidebarWidth: number;
  liveRefreshTime: number;
  renderCurrentTime?: (v: CurrentTimeIndicator) => React.ReactElement;
}

const { CurrentTimeBox, CurrentTimeContent } = CurrentTimeStyled;

export function CurrentTime(props: CurrentTimeProps) {
  const { isVerticalMode, isBaseTimeFormat, isRTL } = props;
  const { mode, liveRefreshTime, startDate, endDate, hoursInDays } = props;
  const { dayWidth, timelineHeight, hourWidth, sidebarWidth } = props;
  const { renderCurrentTime } = props;

  const { showLine, positionX } = useLine({
    hoursInDays,
    startDate,
    endDate,
    dayWidth,
    hourWidth,
    sidebarWidth,
    liveRefreshTime,
  });

  const { time, getCurrentTimeStyles } = useCurrentTime({
    isVerticalMode,
    isBaseTimeFormat,
    mode,
    positionX,
    timelineHeight,
  });

  if (!showLine) return null;

  const styles = getCurrentTimeStyles();

  if (renderCurrentTime) {
    return renderCurrentTime({
      isVerticalMode,
      isBaseTimeFormat,
      isRTL,
      time,
      styles,
    });
  }

  return (
    <CurrentTimeBox className="planby-current-time" {...styles.position}>
      <CurrentTimeContent
        className="planby-current-content"
        isVerticalMode={isVerticalMode}
        isBaseTimeFormat={isBaseTimeFormat}
        isRTL={isRTL}
      >
        {time}
      </CurrentTimeContent>
    </CurrentTimeBox>
  );
}
