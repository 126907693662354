import React from "react";
import { useDebouncedCallback } from "use-debounce";
import { startOfDay } from "date-fns";

// Import interfaces
import { HoursInDayDiffTime } from "../helpers/interfaces";

// Import types
import { DateTime, InitialScrollPositions } from "../helpers/types";

// Import helpers
import {
  DEBOUNCE_WAIT,
  DEBOUNCE_WAIT_MAX,
  getHoursInDaysPositionX,
  getPositionX,
  useIsomorphicLayoutEffect,
} from "../helpers";

interface useLayoutProps {
  isVerticalMode: boolean;
  isToday: boolean;
  isInitialScrollToNow: boolean;
  initialScrollPositions: InitialScrollPositions;
  height?: number;
  width?: number;
  hourWidth: number;
  sidebarWidth: number;
  startDate: DateTime;
  endDate: DateTime;
  currentDate: string;
  hoursInDays: HoursInDayDiffTime[];
}

export function useLayout({
  isVerticalMode,
  isToday,
  isInitialScrollToNow,
  initialScrollPositions,
  height,
  width,
  startDate,
  endDate,
  hourWidth,
  sidebarWidth,
  hoursInDays,
}: useLayoutProps) {
  const useIsomorphicEffect = useIsomorphicLayoutEffect();

  const containerRef = React.useRef<HTMLDivElement>(null);
  const scrollBoxRef = React.useRef<HTMLDivElement>(null);
  //-------- State --------
  const [scrollY, setScrollY] = React.useState<number>(0);
  const [scrollX, setScrollX] = React.useState<number>(0);
  const [layoutWidth, setLayoutWidth] = React.useState<number>(width as number);
  const [layoutHeight, setLayoutHeight] = React.useState<number>(
    height as number
  );
  //-------- Variables --------
  const scrollBoxInnerHeight = scrollBoxRef?.current?.scrollHeight;

  const initialScrollPositionsSerialized = JSON.stringify(
    initialScrollPositions
  );

  // -------- Handlers --------
  const handleScrollDebounced = useDebouncedCallback(
    (value) => {
      setScrollY(value.y);
      setScrollX(value.x);
    },
    DEBOUNCE_WAIT,
    { maxWait: DEBOUNCE_WAIT_MAX }
  );

  const handleOnScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent> & { target: Element }) => {
      handleScrollDebounced({ y: e.target.scrollTop, x: e.target.scrollLeft });
    },
    [handleScrollDebounced]
  );

  const hoursInDaysSerialized = JSON.stringify(hoursInDays);
  const areHoursInDays = React.useMemo(
    () => hoursInDays.length > 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoursInDaysSerialized]
  );
  const handleOnScrollToNow = React.useCallback(
    () => {
      if (scrollBoxRef?.current && isToday) {
        const clientWidth = (width ??
          containerRef.current?.clientWidth) as number;

        const date = new Date(startDate);
        let positionX = getPositionX(
          startOfDay(date),
          new Date(),
          startDate,
          endDate,
          hourWidth
        );

        if (areHoursInDays) {
          positionX = getHoursInDaysPositionX({
            hoursInDays,
            hourWidth,
            sidebarWidth,
          });
        }

        const scrollNow = positionX - clientWidth / 2 + sidebarWidth;

        if (isVerticalMode) {
          scrollBoxRef.current.scrollTop = scrollNow + hourWidth;
        } else {
          scrollBoxRef.current.scrollLeft = scrollNow;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isVerticalMode,
      isToday,
      areHoursInDays,
      width,
      hourWidth,
      sidebarWidth,
      startDate,
      endDate,
    ]
  );

  const handleScrollToInitialPositions = React.useCallback(
    () => {
      const isInitialScrollPosition = Object.keys(
        initialScrollPositions
      ).length;
      if (scrollBoxRef?.current && isInitialScrollPosition) {
        const { top = 0, left = 0 } = initialScrollPositions;
        scrollBoxRef.current.scrollTo({ behavior: "smooth", top, left });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialScrollPositionsSerialized, scrollBoxInnerHeight]
  );

  const handleOnScrollTop = React.useCallback(
    (value: number = hourWidth) => {
      if (scrollBoxRef?.current) {
        const top = scrollBoxRef.current.scrollTop + value;
        scrollBoxRef.current.scrollTop = top;
      }
    },
    [hourWidth]
  );

  const handleOnScrollRight = React.useCallback(
    (value: number = hourWidth) => {
      if (scrollBoxRef?.current) {
        const right = scrollBoxRef.current.scrollLeft + value;
        scrollBoxRef.current.scrollLeft = right;
      }
    },
    [hourWidth]
  );

  const handleOnScrollLeft = React.useCallback(
    (value: number = hourWidth) => {
      if (scrollBoxRef?.current) {
        const left = scrollBoxRef.current.scrollLeft - value;
        scrollBoxRef.current.scrollLeft = left;
      }
    },
    [hourWidth]
  );

  const handleResizeDebounced = useDebouncedCallback(
    () => {
      if (containerRef?.current && !width) {
        const container = containerRef.current;
        const { clientWidth } = container;
        setLayoutWidth(clientWidth);
      }
    },
    DEBOUNCE_WAIT * 4,
    { maxWait: DEBOUNCE_WAIT_MAX * 4 }
  );

  // -------- Effects --------
  useIsomorphicEffect(() => {
    if (containerRef?.current) {
      const container = containerRef.current;
      if (!width) {
        const { clientWidth } = container;
        setLayoutWidth(clientWidth);
      }
      if (!height) {
        const { clientHeight } = container;
        setLayoutHeight(clientHeight);
      }
    }
  }, [height, width, startDate]);

  useIsomorphicEffect(() => {
    const isInitialScrollPosition = Object.keys(initialScrollPositions).length;
    if (scrollBoxRef?.current) {
      if (isInitialScrollPosition) {
        handleScrollToInitialPositions();
      } else if (isInitialScrollToNow) {
        handleOnScrollToNow();
      }
    }
  }, [isToday, isInitialScrollToNow, initialScrollPositionsSerialized]);

  useIsomorphicEffect(() => {
    window.addEventListener("resize", handleResizeDebounced);
    return () => {
      window.removeEventListener("resize", handleResizeDebounced);
    };
  }, [width]);

  return {
    containerRef,
    scrollBoxRef,
    scrollX,
    scrollY,
    layoutWidth,
    layoutHeight,
    onScroll: handleOnScroll,
    onScrollToNow: handleOnScrollToNow,
    onScrollTop: handleOnScrollTop,
    onScrollLeft: handleOnScrollLeft,
    onScrollRight: handleOnScrollRight,
  };
}
