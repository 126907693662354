import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Import interfaces
import { Program } from "../helpers/interfaces";
// Import types
import { Position, ResizeMouseUp } from "../helpers/types";

interface UseResizeProps {
  isVerticalMode: boolean;
  isResize: boolean;
  initialPosition: Omit<Position, "edgeEnd">;
  data: Program;
  dayWidth: number;
  contentHeight: number;
  elementRef: React.RefObject<HTMLDivElement>;
  mouseUpCb: (props: ResizeMouseUp) => void;
}

interface DndPosition {
  x: number;
  y: number;
  width: number;
}

export function useResize({
  isResize,
  isVerticalMode,
  data,
  dayWidth,
  contentHeight,
  initialPosition,
  elementRef,
  mouseUpCb,
}: UseResizeProps) {
  const isClicked = useRef<boolean>(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isResizingLeft, setIsResizingLeft] = useState(false);
  const [isResizingRight, setIsResizingRight] = useState(false);

  const [mouseOffsetX, setMouseOffsetX] = useState<number>(0);

  const [position, setPosition] = useState<DndPosition>({
    x: initialPosition.left,
    y: initialPosition.top,
    width: initialPosition.width,
  });

  const [coords, setCoords] = useState<{
    startY: number;
    lastY: number;
    startX: number;
    lastX: number;
  }>({
    startY: initialPosition.top,
    lastY: initialPosition.top,
    startX: initialPosition.left,
    lastX: initialPosition.left,
  });

  const { id, index, since, till } = data;

  const initialWidth = useMemo(
    () => (isVerticalMode ? initialPosition.height : initialPosition.width),
    [isVerticalMode, initialPosition.height, initialPosition.width]
  );

  const handleMouseDown = useCallback(
    (left: boolean = false) =>
      (event: React.MouseEvent) => {
        isClicked.current = true;
        setIsResizing(true);

        const container = elementRef.current;
        if (!container) return;

        setMouseOffsetX(isVerticalMode ? event.pageY : event.pageX);
        coords.startY = event.clientY;
        coords.startX = event.clientX;
        if (left) {
          setIsResizingLeft(true);
          setIsResizingRight(false);
        } else {
          setIsResizingLeft(false);
          setIsResizingRight(true);
        }
      },
    [isVerticalMode, coords, elementRef]
  );

  const handleMouseUp = useCallback(
    () => {
      if (isVerticalMode) {
        coords.lastY = elementRef.current?.offsetTop as number;
        coords.lastX = elementRef.current?.offsetLeft as number;
        position.width = elementRef.current?.offsetHeight as number;
      } else {
        coords.lastY = elementRef.current?.offsetTop as number;
        coords.lastX = elementRef.current?.offsetLeft as number;
        position.width = elementRef.current?.offsetWidth as number;
      }

      const options = {
        id,
        index,
        since,
        till,
      };
      if (
        !isVerticalMode &&
        isClicked.current &&
        (initialPosition.left !== coords.lastX ||
          initialPosition.width !== position.width)
      ) {
        mouseUpCb({
          ...options,
          top: coords.lastY,
          left: coords.lastX,
          width: position.width,
          initialPositionTop: initialPosition.top,
          initialPositionLeft: initialPosition.left,
          initialWidth: initialPosition.width,
        });
      }
      if (
        isVerticalMode &&
        isClicked.current &&
        (initialPosition.top !== coords.lastY ||
          initialPosition.width !== position.width)
      ) {
        mouseUpCb({
          ...options,
          top: coords.lastX,
          left: coords.lastY,
          width: position.width,
          initialPositionTop: initialPosition.left,
          initialPositionLeft: initialPosition.top,
          initialWidth: initialPosition.height,
        });
      }
      setIsResizing(false);
      isClicked.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isVerticalMode,
      id,
      index,
      since,
      till,
      initialWidth,
      initialPosition.left,
      initialPosition.width,
      mouseUpCb,
    ]
  );

  const initialPositionSerialized = JSON.stringify(initialPosition);
  useEffect(() => {
    if (isVerticalMode) {
      setCoords({
        startY: initialPosition.top,
        lastY: initialPosition.top,
        startX: initialPosition.left,
        lastX: initialPosition.left,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerticalMode, initialPositionSerialized]);

  useEffect(() => {
    function handleMouseMove(event: MouseEvent) {
      if (!isResizing) return;
      if (!isClicked.current) return;
      if (!elementRef.current) return;

      const element = elementRef.current;
      const elementRect = element.getBoundingClientRect();

      if (isVerticalMode) {
        const offsetHeight = elementRef.current?.offsetHeight as number;
        const nextY = event.clientY - coords.startY + coords.lastY;
        const newHeighthTop = initialWidth - (event.pageY - mouseOffsetX);
        const newHeightBottom = event.clientY - elementRect.top + 14;

        const isInLeftLayout =
          nextY >= 0 && nextY <= contentHeight - offsetHeight;
        const isInRightLayout = coords.lastY + newHeightBottom <= dayWidth;
        if (isResizingLeft && isInLeftLayout && newHeighthTop >= 50) {
          const top = initialPosition.top + (event.pageY - mouseOffsetX);
          element.style.height = `${newHeighthTop}px`;
          element.style.top = `${top}px`;
          setPosition((prev) => ({ ...prev, y: top, width: newHeighthTop }));
        } else if (
          isResizingRight &&
          isInRightLayout &&
          newHeightBottom >= 50
        ) {
          element.style.height = `${newHeightBottom}px`;
          element.style.top = `${initialPosition.top}px`;
          setPosition((prev) => ({
            ...prev,
            y: initialPosition.top,
            width: newHeightBottom,
          }));
        }
      } else {
        const offsetWidth = elementRef.current?.offsetWidth as number;
        const nextX = event.clientX - coords.startX + coords.lastX;
        const newWidthLeft = initialWidth - (event.pageX - mouseOffsetX);
        const newWidthRight = event.clientX - elementRect.left + 14;
        const isInLeftLayout = nextX >= 0 && nextX <= dayWidth - offsetWidth;
        const isInRightLayout = coords.lastX + newWidthRight <= dayWidth;
        if (isResizingLeft && isInLeftLayout && newWidthLeft >= 50) {
          const left = initialPosition.left + (event.pageX - mouseOffsetX);
          element.style.width = `${newWidthLeft}px`;
          element.style.left = `${left}px`;
          setPosition((prev) => ({ ...prev, x: left, width: newWidthLeft }));
        } else if (isResizingRight && isInRightLayout && newWidthRight >= 50) {
          element.style.width = `${newWidthRight}px`;
          setPosition((prev) => ({ ...prev, width: newWidthRight }));
        }
      }
    }

    if (isResize) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    isResize,
    isVerticalMode,
    isResizing,
    isResizingLeft,
    isResizingRight,
    dayWidth,
    contentHeight,
    initialWidth,
    initialPosition.top,
    initialPosition.left,
    coords,
    mouseOffsetX,
    elementRef,
    handleMouseUp,
  ]);

  if (isResize) {
    return {
      width: position.width,
      initialWidth: isVerticalMode
        ? initialPosition.height
        : initialPosition.width,
      currentPositionX: isVerticalMode ? position.y : position.x,
      resizeEvents: {
        isResizing,
        resources: { ref: elementRef, isResizing },
        eventsLeft: {
          isResize,
          isVerticalMode,
          left: true,
          onMouseDown: handleMouseDown(true),
          onMouseUp: handleMouseUp,
        },
        eventsRight: {
          isResize,
          isVerticalMode,
          onMouseDown: handleMouseDown(),
          onMouseUp: handleMouseUp,
        },
      },
    };
  }

  return {
    currentPositionX: isVerticalMode
      ? initialPosition.top
      : initialPosition.left,
    width: isVerticalMode ? initialPosition.height : initialPosition.width,
    resizeEvents: {
      isResizing: false,
      resources: {},
      eventsLeft: {},
      eventsRight: {},
    },
  };
}
