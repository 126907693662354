// Import types
import { ChannelWithPosition } from "./types";

export const getChannelVerticalPosition = (
  channel: ChannelWithPosition,
  isVerticalMode: boolean
) => {
  if (isVerticalMode) {
    return {
      ...channel,
      position: {
        ...channel.position,
        left: channel.position.top,
        width: channel.position.height,
      },
    };
  }

  return channel;
};
