import styled from "@emotion/styled/macro";
import { Layers, Theme } from "../helpers";

export const lineStyles = {
  position: "absolute",
  top: 64,
  width: 3,
};

export const LineBox = styled.div<{
  isVerticalMode: boolean;
  isTimeline: boolean;
  height: number;
  left: number;
  zIndex: Layers;
  theme?: Theme;
}>`
  position: ${lineStyles.position};
  top: ${({ isTimeline }) => (isTimeline ? lineStyles.top : 0)}px;
  left: ${({ left }) => left}px;
  height: ${({ height }) => height}px;
  width: ${lineStyles.width}px;
  background: ${({ theme }) => theme.green[300]};
  pointer-events: none;
  z-index: ${({ zIndex }) => zIndex};

  ${({ isVerticalMode, isTimeline, left, height }) =>
    isVerticalMode &&
    ` 
      top: ${left}px;
      left: ${isTimeline ? lineStyles.top : 0}px;
      height: ${lineStyles.width}px;
      width: ${height}px;
  `}
`;
