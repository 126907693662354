import { differenceInMinutes, getTime } from "date-fns";
// Import interfaces
import {
  Channel,
  ChannelWithOmittedUuid,
  HoursInDayDiffTime,
  Program,
  ProgramWithOmittedUuid,
} from "./interfaces";

// Import types
import {
  ProgramWithPosition,
  Position,
  DateTime,
  ChannelWithPosition,
} from "./types";

// Import variables
import { HOUR_IN_MINUTES, OVERLAP_MODES } from "./variables";

// Import helpers
import {
  formatTime,
  roundToMinutes,
  isYesterday as isYesterdayTime,
} from "./time";
import { getDate } from "./common";
import { setChannelEpgIndexes } from "./layout";

// -------- Program width --------
const getItemDiffWidth = (diff: number, hourWidth: number) =>
  (diff * hourWidth) / HOUR_IN_MINUTES;

export const getPositionX = (
  since: DateTime,
  till: DateTime,
  startDate: DateTime,
  endDate: DateTime,
  hourWidth: number
) => {
  const isTomorrow = getTime(getDate(till)) > getTime(getDate(endDate));
  const isYesterday = getTime(getDate(since)) < getTime(getDate(startDate));

  // When time range is set to 1 hour and program time is greater than 1 hour
  if (isYesterday && isTomorrow) {
    const diffTime = differenceInMinutes(
      roundToMinutes(getDate(endDate)),
      getDate(startDate)
    );
    return getItemDiffWidth(diffTime, hourWidth);
  }

  if (isYesterday) {
    const diffTime = differenceInMinutes(
      roundToMinutes(getDate(till)),
      getDate(startDate)
    );
    return getItemDiffWidth(diffTime, hourWidth);
  }

  if (isTomorrow) {
    const diffTime = differenceInMinutes(
      getDate(endDate),
      roundToMinutes(getDate(since))
    );

    if (diffTime < 0) return 0;
    return getItemDiffWidth(diffTime, hourWidth);
  }

  const diffTime = differenceInMinutes(
    roundToMinutes(getDate(till)),
    roundToMinutes(getDate(since))
  );

  return getItemDiffWidth(diffTime, hourWidth);
};

// -------- Channel position in the Epg --------
export const getChannelPosition = (
  channelIndex: number,
  itemHeight: number
) => {
  const top = itemHeight * channelIndex;
  const position = {
    top,
    height: itemHeight,
  };
  return position;
};
// -------- Program position in the Epg --------
export const getProgramPosition = (
  program: Program,
  channelIndex: number,
  itemHeight: number,
  hourWidth: number,
  startDate: DateTime,
  endDate: DateTime,
  isVerticalMode: boolean
) => {
  const item = {
    ...program,
    since: formatTime(program.since),
    till: formatTime(program.till),
  };
  const isYesterday = isYesterdayTime(item.since, startDate);

  let width = getPositionX(
    item.since,
    item.till,
    startDate,
    endDate,
    hourWidth
  );
  const top = itemHeight * channelIndex;
  let left = getPositionX(startDate, item.since, startDate, endDate, hourWidth);
  const edgeEnd = getPositionX(
    startDate,
    item.till,
    startDate,
    endDate,
    hourWidth
  );

  if (isYesterday) left = 0;
  // If item has negative top position, it means that it is not visible in this day
  if (top < 0) width = 0;

  let position = {
    width,
    height: itemHeight,
    top,
    left,
    edgeEnd,
  };

  if (isVerticalMode) {
    position = {
      ...position,
      top: left,
      left: top,
      width: itemHeight,
      height: width,
    };
  }
  return { position, data: item };
};

// -------- Program position with day hours --------
interface ProgramPositionWithDayHours {
  isVerticalMode: boolean;
  hoursInDays: HoursInDayDiffTime[];
  position: ProgramWithPosition["position"];
  since: string;
  hourWidth: number;
}
const getProgramPositionWithDayHours = ({
  isVerticalMode,
  hoursInDays,
  position,
  since,
  hourWidth,
}: ProgramPositionWithDayHours) => {
  const day = hoursInDays.find((day) => since.includes(day.date));
  if (!day) return { position };

  if (isVerticalMode) {
    const diffHours = day.diffLeft * hourWidth;
    const top = position.top - diffHours;
    const edgeEnd = position.edgeEnd - diffHours;
    return { ...position, top, edgeEnd };
  }

  const diffHours = day.diffLeft * hourWidth;
  const left = position.left - diffHours;
  const edgeEnd = position.edgeEnd - diffHours;
  return { ...position, left, edgeEnd };
};

// -------- Converted programs with position data --------
interface ConvertedPrograms {
  isVerticalMode: boolean;
  isOverlapEnabled: boolean;
  programChannelMapKey: string;
  data: ProgramWithOmittedUuid[];
  channels: Channel[];
  startDate: DateTime;
  endDate: DateTime;
  itemHeight: number;
  hourWidth: number;
  hoursInDays: HoursInDayDiffTime[];
}
export const getConvertedPrograms = ({
  isVerticalMode,
  isOverlapEnabled,
  programChannelMapKey,
  data,
  channels,
  startDate,
  endDate,
  itemHeight,
  hourWidth,
  hoursInDays,
}: ConvertedPrograms) => {
  let first = 0;
  return data.map((next, index, arr) => {
    next["channelUuid"] = next[programChannelMapKey];

    if (arr[index - 1]?.[programChannelMapKey] !== next[programChannelMapKey]) {
      first = index;
      setChannelEpgIndexes({ uuid: next[programChannelMapKey], first });
    }
    if (next[programChannelMapKey] !== arr[index + 1]?.[programChannelMapKey]) {
      setChannelEpgIndexes({
        uuid: next[programChannelMapKey],
        first,
        last: index,
      });
    } else {
      setChannelEpgIndexes({
        uuid: next[programChannelMapKey],
        first,
        last: index,
      });
    }

    const channelIndex = channels.findIndex(
      ({ uuid }) => uuid === next.channelUuid
    );

    next["channelIndex"] = channelIndex;
    next["channelPosition"] = channels[channelIndex]?.position;
    next["index"] = index;

    const programData = getProgramPosition(
      next as Program,
      channelIndex,
      itemHeight,
      hourWidth,
      startDate,
      endDate,
      isVerticalMode
    );

    if (isOverlapEnabled && channelIndex > 0) {
      const { position } = channels[channelIndex - 1];
      const newPositionTop = position.top + position.height;
      if (isVerticalMode) {
        programData.position.left = newPositionTop;
      } else {
        programData.position.top = newPositionTop;
      }
    }

    if (hoursInDays.length === 0) return programData;

    const newPosition = getProgramPositionWithDayHours({
      isVerticalMode,
      hoursInDays,
      position: programData.position,
      since: programData.data.since,
      hourWidth,
    }) as ProgramWithPosition["position"];

    programData["position"] = newPosition;
    return programData;
  }, [] as ProgramWithPosition[]);
};

// -------- Converted channels with position data --------

export const getConvertedChannels = (
  isOverlapEnabled: boolean,
  overlapMode: string,
  layerOverlapLevel: number,
  channels: ChannelWithOmittedUuid[],
  itemHeight: number,
  channelMapKey: string,
  channelOverlapsCount: Record<string, number>
) => {
  let top = 0;
  const isStackMode = overlapMode === OVERLAP_MODES.STACK;
  const isLayerMode = overlapMode === OVERLAP_MODES.LAYER;
  return channels.map((channel, index) => {
    const overlap = channelOverlapsCount[channel[channelMapKey]];

    let largestSizeLength = 1;
    const overlapsLength = overlap ?? 0;

    if (
      isOverlapEnabled &&
      (isStackMode || isLayerMode) &&
      overlapsLength > 0
    ) {
      largestSizeLength = overlap;
    }

    const position = getChannelPosition(index, itemHeight * largestSizeLength);

    if (isOverlapEnabled && isStackMode) {
      position.top = top;
      top = top + position.height;
    }

    if (isOverlapEnabled && isLayerMode) {
      if (overlapsLength > 0) {
        position.top = top;
        position.height =
          overlapsLength <= 1
            ? position.height
            : itemHeight * layerOverlapLevel * (overlapsLength - 1) +
              itemHeight;
        top = top + position.height;
      } else {
        position.top = top;
        top = top + position.height;
      }
    }

    return {
      ...channel,
      uuid: channel[channelMapKey],
      index,
      position,
    };
  }) as ChannelWithPosition[];
};

// -------- Dynamic virtual program visibility in the EPG --------
interface ItemVisibility {
  position: Position;
  scrollY: number;
  scrollX: number;
  containerHeight: number;
  containerWidth: number;
  isVerticalMode: boolean;
  itemOverscan: number;
  overlapsCount: number;
}
export const getItemVisibility = ({
  position,
  scrollY,
  scrollX,
  containerHeight,
  containerWidth,
  itemOverscan,
  overlapsCount,
  isVerticalMode,
}: ItemVisibility) => {
  // Set item visibility for vertical mode
  const _overlapsCount = overlapsCount === 0 ? 1 : overlapsCount;
  if (isVerticalMode) {
    if (position.height <= 0) {
      return false;
    }

    if (scrollX > position.left + position.width * 2 * _overlapsCount) {
      return false;
    }

    if (scrollX + containerWidth <= position.left) {
      return false;
    }

    if (
      scrollY + containerHeight >= position.top &&
      scrollY <= position.edgeEnd
    ) {
      return true;
    }
  } else {
    // Set item visibility for horizontal mode
    if (position.width <= 0) {
      return false;
    }

    if (scrollY > position.top + itemOverscan * 3 * _overlapsCount) {
      return false;
    }

    if (scrollY + containerHeight <= position.top) {
      return false;
    }

    if (
      scrollX + containerWidth >= position.left &&
      scrollX <= position.edgeEnd
    ) {
      return true;
    }
  }

  return false;
};

interface SidebarItemVisibility {
  isVerticalMode: boolean;
  itemOverscan: number;
  position: Pick<Position, "top" | "height"> & Partial<Pick<Position, "left">>;
  scrollX: number;
  scrollY: number;
  containerHeight: number;
  containerWidth: number;
}
export const getSidebarItemVisibility = ({
  position,
  scrollX,
  scrollY,
  containerHeight,
  containerWidth,
  itemOverscan,
  isVerticalMode,
}: SidebarItemVisibility) => {
  // Set item visibility for vertical mode
  if (isVerticalMode) {
    const left = (position.left as number) + position.height;
    if (scrollX > left + itemOverscan * 3) {
      return false;
    }

    if (scrollX + containerWidth <= left - position.height) {
      return false;
    }
  } else {
    // Set item visibility for horizontal mode
    if (scrollY > position.top + position.height + itemOverscan * 3) {
      return false;
    }

    if (scrollY + containerHeight <= position.top) {
      return false;
    }
  }

  return true;
};

interface TimelineItemVisibility {
  position: Pick<Position, "left" | "width">;
  scrollY: number;
  scrollX: number;
  containerWidth: number;
  containerHeight: number;
  isVerticalMode: boolean;
}
export const getTimelineItemVisibility = ({
  position,
  scrollY,
  scrollX,
  containerHeight,
  containerWidth,
  isVerticalMode,
}: TimelineItemVisibility) => {
  // Set item visibility for vertical mode
  if (isVerticalMode) {
    if (scrollY > position.left + position.width * 2) {
      return false;
    }

    if (scrollY + containerHeight <= position.left) {
      return false;
    }
  } else {
    // Set item visibility for horizontal mode
    if (scrollX > position.left + position.width * 2) {
      return false;
    }

    if (scrollX + containerWidth <= position.left) {
      return false;
    }
  }

  return true;
};
