import {
  format,
  roundToNearestMinutes,
  startOfDay,
  addDays,
  differenceInHours,
  isWithinInterval,
} from "date-fns";
// Import common helpers
import { getDate } from "./common";
// Import interfaces
import { HoursInDay, HoursInDayDiffTime, Mode } from "./interfaces";
// Import types
import { DateTime as DateRangeTime } from "./types";
// Variables
import { TIME_FORMAT } from "./variables";
import { getPositionX } from "./epg";

type DateTime = number | string | Date;

const getTime = (date: DateTime) => new Date(date).getTime();

export const getLiveStatus = (since: DateTime, till: DateTime) => {
  const nowTime = getTime(new Date());
  const sinceTime = getTime(since);
  const sinceTill = getTime(till);
  return nowTime >= sinceTime && sinceTill > nowTime;
};

export const formatTime = (date: DateTime) =>
  format(new Date(date), TIME_FORMAT.DEFAULT).replace(/\s/g, "T");

export const roundToMinutes = (date: DateTime) =>
  roundToNearestMinutes(new Date(date));

export const isYesterday = (since: DateTime, startDate: DateTime) => {
  const sinceTime = getTime(new Date(since));
  const startDateTime = getTime(new Date(startDate));

  return startDateTime > sinceTime;
};

export const isFutureTime = (date: DateTime) => {
  const dateTime = getTime(new Date(date));
  const now = getTime(new Date());
  return dateTime > now;
};

export const getTimeRangeDates = (
  startDate: DateRangeTime,
  endDate: DateRangeTime
) => {
  let endDateValue = endDate;
  if (endDate === "") {
    endDateValue = formatTime(startOfDay(addDays(getDate(startDate), 1)));
  }

  return { startDate, endDate: endDateValue };
};

interface FormatWeekMonthDate {
  date: string;
  mode: Mode;
  isBaseTimeFormat: boolean;
}
export const getFormattedWeekMonthDate = ({
  date,
  mode,
  isBaseTimeFormat,
}: FormatWeekMonthDate) => {
  if (mode.type === "week") {
    if (mode.style === "modern") {
      return format(getDate(date), TIME_FORMAT.DAY_DATE);
    }
    const dateFormat = isBaseTimeFormat
      ? TIME_FORMAT.BASE_DAY_MONTH
      : TIME_FORMAT.DAY_MONTH;
    return format(getDate(date), dateFormat);
  }
  return format(getDate(date), TIME_FORMAT.MONTH);
};

interface NumberOfHoursInDays {
  startDate: DateRangeTime;
  customHoursInDays: HoursInDay[];
}
export const getNumberOfHoursInDays = ({
  startDate,
  customHoursInDays,
}: NumberOfHoursInDays) => {
  if (customHoursInDays.length === 0) return [];
  let itemsDiffInHours = 0;
  const data = customHoursInDays.reduce((acc, item) => {
    let diffLeft = 0;
    const prevItem = acc[acc.length - 1];
    if (!prevItem) {
      diffLeft = differenceInHours(
        getDate(item.startTimeHour),
        getDate(startDate)
      );
    }
    if (prevItem) {
      diffLeft = differenceInHours(
        getDate(item.startTimeHour),
        getDate(prevItem.endTimeHour)
      );
      diffLeft += prevItem.diffLeft;
      itemsDiffInHours += prevItem.diffInHours;
    }

    const diffInHours = differenceInHours(
      getDate(item.endTimeHour),
      getDate(item.startTimeHour)
    );
    const startTime = Number(format(getDate(item.startTimeHour), "H"));
    const endTime = Number(format(getDate(item.endTimeHour), "H"));
    const obj = {
      ...item,
      diffLeft,
      diffInHours,
      prevItemDiffInHours: itemsDiffInHours,
      startTime,
      endTime,
    };
    acc.push(obj);
    return acc;
  }, [] as HoursInDayDiffTime[]);

  return data;
};

export const getTodayHoursInDays = (hoursInDays: HoursInDayDiffTime[]) => {
  const date = hoursInDays.filter((item) =>
    isWithinInterval(new Date(), {
      start: new Date(item.startTimeHour),
      end: new Date(item.endTimeHour),
    })
  );

  return date.length > 0;
};

interface HoursInDaysPositionX {
  hoursInDays: HoursInDayDiffTime[];
  hourWidth: number;
  sidebarWidth: number;
  cb?: (v: boolean) => void;
}
export const getHoursInDaysPositionX = ({
  hoursInDays,
  hourWidth,
  sidebarWidth,
  cb,
}: HoursInDaysPositionX) => {
  const date = hoursInDays.filter((item) =>
    isWithinInterval(new Date(), {
      start: new Date(item.startTimeHour),
      end: new Date(item.endTimeHour),
    })
  );
  if (date.length === 0) {
    cb?.(false);
    return 0;
  }
  const day = date[0];
  const positionX = getPositionX(
    startOfDay(new Date(day.startTimeHour)),
    new Date(),
    day.startTimeHour,
    day.endTimeHour,
    hourWidth
  );
  return positionX + sidebarWidth + day.prevItemDiffInHours * hourWidth;
};
