import { useCallback } from "react";
// Import interfaces
import { Mode, Grid as IGrid, HoursInDayDiffTime } from "../helpers/interfaces";
// Import types
import {
  BaseTimeFormat,
  ChannelWithPosition,
  Position,
} from "../helpers/types";
// Import helpers
import { getClickGridItemData } from "../helpers";

interface UseGridProps {
  isVerticalMode: boolean;
  isBaseTimeFormat: BaseTimeFormat;
  hourWidth: number;
  dayWidth: number;
  days: string[];
  hoursInDays: HoursInDayDiffTime[];
  months: string[];
  numberOfDays: number;
  numberOfHoursInDay: number;
  numberOfMonths: number;
  offsetStartHoursRange: number;
  grid: IGrid;
  mode: Mode;
  sidebarWidth: number;
  timelineHeight: number;
}

export function useGrid({
  mode,
  grid,
  timelineHeight,
  sidebarWidth,
  hourWidth,
  dayWidth,
  ...rest
}: UseGridProps) {
  const {
    isVerticalMode,
    isBaseTimeFormat,
    days,
    hoursInDays,
    months,
    numberOfDays,
    numberOfHoursInDay,
    numberOfMonths,
    offsetStartHoursRange,
  } = rest;
  const handleItemClick = useCallback(
    (
        item: { position: Position; channel: ChannelWithPosition },
        index?: number
      ) =>
      () => {
        getClickGridItemData({
          isVerticalMode,
          index,
          item,
          grid,
          mode,
          dayWidth,
          hourWidth,
          timelineHeight,
          sidebarWidth,
          isBaseTimeFormat,
          days,
          hoursInDays,
          months,
          numberOfDays,
          numberOfHoursInDay,
          numberOfMonths,
          offsetStartHoursRange,
        });
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isVerticalMode,
      hourWidth,
      dayWidth,
      sidebarWidth,
      isBaseTimeFormat,
      days,
      hoursInDays,
      months,
      numberOfDays,
      numberOfHoursInDay,
      numberOfMonths,
      offsetStartHoursRange,
      mode.type,
    ]
  );
  return {
    onItemClick: handleItemClick,
  };
}
