import React from "react";
import { startOfDay } from "date-fns";

// Import interfaces
import { HoursInDayDiffTime } from "../helpers/interfaces";

// Import types
import { DateTime } from "../helpers/types";

// Import helpers
import {
  SECONDS_IN_MINUTE,
  HOUR_IN_MINUTES,
  getPositionX,
  getTodayHoursInDays,
  getHoursInDaysPositionX,
} from "../helpers";

// Import hooks
import { useInterval } from ".";

interface useLineProps {
  startDate: DateTime;
  endDate: DateTime;
  dayWidth: number;
  hourWidth: number;
  sidebarWidth: number;
  hoursInDays: HoursInDayDiffTime[];
  liveRefreshTime: number;
}

export function useLine({
  startDate,
  endDate,
  hoursInDays,
  dayWidth,
  hourWidth,
  sidebarWidth,
  liveRefreshTime,
}: useLineProps) {
  const [showLine, setShowLine] = React.useState<boolean>(true);
  const [positionX, setPositionX] = React.useState<number>(getInitialState);

  // Variables
  const hoursInDaysSerialized = JSON.stringify(hoursInDays);
  const liveIntervalTime = liveRefreshTime * 1000;
  const isDayEnd = positionX <= dayWidth;
  const isScrollX = React.useMemo(
    () => (isDayEnd ? liveIntervalTime : null),
    [isDayEnd, liveIntervalTime]
  );

  // Helpers
  function getInitialState() {
    if (hoursInDays.length > 0)
      return getHoursInDaysPositionX({
        hoursInDays,
        hourWidth,
        sidebarWidth,
        cb: setShowLine,
      });

    const positionX = getPositionX(
      startOfDay(new Date(startDate)),
      new Date(),
      startDate,
      endDate,
      hourWidth
    );
    return positionX + sidebarWidth;
  }

  // Effects
  useInterval(() => {
    const offset = hourWidth / HOUR_IN_MINUTES;
    const step = offset / SECONDS_IN_MINUTE;
    const positionOffset = step * liveRefreshTime;

    if (hoursInDays.length > 0) {
      const idCurrentDay = getTodayHoursInDays(hoursInDays);

      if (idCurrentDay) {
        setShowLine(true);

        setPositionX((prev) => prev + positionOffset);
      } else {
        setShowLine(false);
      }
    } else {
      setPositionX((prev) => prev + positionOffset);
    }
  }, isScrollX);

  React.useEffect(() => {
    if (hoursInDays.length > 0) {
      const newPositionX = getHoursInDaysPositionX({
        hoursInDays,
        hourWidth,
        sidebarWidth,
        cb: setShowLine,
      });
      setPositionX(newPositionX);
    } else {
      const date = new Date(startDate);
      const positionX = getPositionX(
        startOfDay(date),
        new Date(),
        startDate,
        endDate,
        hourWidth
      );
      const newPositionX = positionX + sidebarWidth;
      setPositionX(newPositionX);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, sidebarWidth, hourWidth, hoursInDaysSerialized]);

  return { showLine, positionX };
}
