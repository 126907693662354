import React, { useCallback, useRef } from "react";
import { format } from "date-fns";

// Import interfaces
import { DragAndDrop, Mode } from "../helpers/interfaces";

// Import types
import {
  ProgramItem,
  BaseTimeFormat,
  DragMouseUp,
  ResizeMouseUp,
} from "../helpers/types";

// Import helpers
import {
  TIME_FORMAT,
  calculateItemDragSinceTill,
  getLiveStatus,
  omit,
} from "../helpers";

// Import hooks
import { useInterval } from "./useInterval";
import { useDrag } from "./useDrag";
import { useResize } from "./useResize";

interface useProgramProps<T> {
  isVerticalMode?: boolean;
  isRTL?: boolean;
  isBaseTimeFormat: BaseTimeFormat;
  isResize?: boolean;
  contentHeight: number;
  dayWidth: number;
  hourWidth: number;
  program: T;
  liveRefreshTime: number;
  minWidth?: number;
  itemHeight: number;
  mode: Mode;
  dnd: DragAndDrop;
  dragMouseUp: (data: DragMouseUp) => void;
  resizeMouseUp: (data: ResizeMouseUp) => void;
}

export function useProgram<T extends ProgramItem>({
  isVerticalMode = false,
  isRTL = false,
  isResize = false,
  isBaseTimeFormat,
  contentHeight,
  dayWidth,
  itemHeight,
  hourWidth,
  minWidth = 200,
  program,
  liveRefreshTime,
  mode,
  dnd,
  dragMouseUp,
  resizeMouseUp,
}: useProgramProps<T>) {
  const elementRef = useRef<HTMLDivElement>(null);

  const { data, position } = program;
  const { width, height } = position;
  const { since, till } = data;

  // Hooks
  const [isLive, setIsLive] = React.useState<boolean>(() =>
    getLiveStatus(since, till)
  );

  const options = {
    isVerticalMode,
    initialPosition: position,
    data,
    dayWidth,
    contentHeight,
    elementRef,
  };

  const resizeOptions = {
    ...options,
    isResize,
    mouseUpCb: resizeMouseUp,
  };

  const {
    currentPositionX: resizeCurrentPositionX,
    width: resizeWidth,
    resizeEvents,
  } = useResize(resizeOptions);

  const dragOptions = {
    ...options,
    isDndEnabled: dnd.enabled ? !resizeEvents.resources.isResizing : false,
    isDndMutlirows: dnd.mode === "multi-rows",
    itemHeight,
    mouseUpCb: dragMouseUp,
  };

  const { currentPositionX, dndEvents } = useDrag(dragOptions);

  // Variables
  const newPosition = { ...omit(position, "egdeEnd") };
  const styles = { width, position: newPosition };

  const formatTime = (
    date: string | number | Date,
    formatType: string = TIME_FORMAT.HOURS_MIN
  ) => format(new Date(date), formatType).replace(/\s/g, "");

  const set12HoursTimeFormat = () => {
    if (mode.type === "week" || mode.type === "month") {
      if (isBaseTimeFormat) return TIME_FORMAT.BASE_DAY_MONTH;
      return TIME_FORMAT.DAY_MONTH;
    }

    if (isBaseTimeFormat) return TIME_FORMAT.BASE_HOURS_TIME;
    return TIME_FORMAT.HOURS_MIN;
  };

  const getRTLSinceTime = (since: string | number | Date) =>
    isRTL ? till : since;

  const getRTLTillTime = (till: string | number | Date) =>
    isRTL ? since : till;

  // Effects
  useInterval(() => {
    const status = getLiveStatus(since, till);
    setIsLive(status);
  }, liveRefreshTime);

  const isMinWidth = isVerticalMode ? height > minWidth : width > minWidth;

  const getMouseEventTempTime = () => {
    const left = resizeEvents.isResizing
      ? resizeCurrentPositionX
      : currentPositionX;
    let options = {
      id: data.id,
      index: data.index,
      since: data.since,
      till: data.till,
      top: 0,
      left,
      initialPositionTop: position.top,
      initialPositionLeft: position.left,
      initialWidth: width,
      width: resizeWidth,
      hourWidth,
    };

    if (isVerticalMode) {
      options.initialPositionLeft = position.top;
    }

    const { since, till } = calculateItemDragSinceTill(options);

    const formatTempTime = (time: string) =>
      formatTime(getRTLSinceTime(time), set12HoursTimeFormat()).toLowerCase();

    return { since: formatTempTime(since), till: formatTempTime(till) };
  };

  const getMouseEvents = useCallback(
    () => ({
      ...resizeEvents.resources,
      ...dndEvents,
    }),
    [dndEvents, resizeEvents.resources]
  );
  const isMouseEvent =
    dndEvents.isDragging ||
    (resizeEvents.resources.isResizing as boolean) ||
    false;
  return {
    isMouseEvent,
    isLive,
    isMinWidth,
    isRTL,
    resizeEvents,
    formatTime,
    set12HoursTimeFormat,
    getMouseEvents,
    getMouseEventTempTime,
    getRTLSinceTime,
    getRTLTillTime,
    styles,
  };
}
