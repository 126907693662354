import { useRef, useState, useCallback, MouseEvent, useEffect } from "react";
// Import interfaces
import { Program } from "../helpers/interfaces";
// Import types
import { DragMouseUp, Position } from "../helpers/types";
// Import helpers
import { getDefaultDragProps } from "../helpers";

interface DndPosition {
  x: number;
  y: number;
}

interface UseDragProps {
  isVerticalMode: boolean;
  isDndEnabled: boolean;
  isDndMutlirows: boolean;
  initialPosition: Omit<Position, "edgeEnd">;
  data: Program;
  dayWidth: number;
  itemHeight: number;
  contentHeight: number;
  elementRef: React.RefObject<HTMLDivElement>;
  mouseUpCb: (props: DragMouseUp) => void;
}

export function useDrag(props: UseDragProps) {
  const {
    isDndEnabled,
    isDndMutlirows,
    isVerticalMode,
    initialPosition,
    data,
    dayWidth,
    itemHeight,
    contentHeight,
    elementRef,
    mouseUpCb,
  } = props;
  const isClicked = useRef<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState<DndPosition>({
    x: initialPosition.left,
    y: initialPosition.top,
  });

  const [coords, setCoords] = useState<{
    startY: number;
    lastY: number;
    startX: number;
    lastX: number;
  }>({
    startY: initialPosition.top,
    lastY: initialPosition.top,
    startX: initialPosition.left,
    lastX: initialPosition.left,
  });

  const { id, index, since, till, channelPosition } = data;

  const handleMouseDown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      isClicked.current = true;
      setIsDragging(true);
      coords.startY = e.clientY;
      coords.startX = e.clientX;
    },
    [coords]
  );

  const handleMouseUp = useCallback(() => {
    if (isVerticalMode) {
      coords.lastY = elementRef.current?.offsetTop as number;
      coords.lastX = elementRef.current?.offsetLeft as number;
    } else {
      coords.lastY = elementRef.current?.offsetTop as number;
      coords.lastX = elementRef.current?.offsetLeft as number;
    }

    const options = {
      id,
      index,
      since,
      till,
    };

    if (
      !isVerticalMode &&
      isClicked.current &&
      (initialPosition.left !== coords.lastX ||
        initialPosition.top !== coords.lastY)
    ) {
      const optionsYX = {
        ...options,
        top: coords.lastY,
        left: coords.lastX,
        initialPositionLeft: initialPosition.left,
        initialPositionTop: initialPosition.top,
      };

      mouseUpCb(optionsYX);
    }
    if (
      isVerticalMode &&
      isClicked.current &&
      (initialPosition.left !== coords.lastX ||
        initialPosition.top !== coords.lastY)
    ) {
      const optionsY = {
        ...options,
        top: coords.lastX,
        left: coords.lastY,
        initialPositionLeft: initialPosition.top,
        initialPositionTop: initialPosition.left,
      };

      mouseUpCb(optionsY);
    }
    isClicked.current = false;
    setIsDragging(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isVerticalMode,
    initialPosition.top,
    initialPosition.left,
    id,
    index,
    since,
    till,
    mouseUpCb,
  ]);

  const initialPositionSerialized = JSON.stringify(initialPosition);
  useEffect(() => {
    if (isVerticalMode) {
      setCoords({
        startY: initialPosition.top,
        lastY: initialPosition.top,
        startX: initialPosition.left,
        lastX: initialPosition.left,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerticalMode, initialPositionSerialized]);

  useEffect(() => {
    function handleMouseMove(e: globalThis.MouseEvent) {
      if (!isDndEnabled) return;
      if (!isClicked.current) return;
      if (!elementRef.current) return;

      const offsetWidth = elementRef.current?.offsetWidth as number;
      const offsetHeight = elementRef.current?.offsetHeight as number;
      const elementChannel = channelPosition;

      if (isVerticalMode) {
        const nextY = e.clientY - coords.startY + coords.lastY;

        if (isDndMutlirows) {
          const nextX = e.clientX - coords.startX + coords.lastX;
          const nextXSnap = Math.round(nextX / itemHeight) * itemHeight;
          if (
            nextXSnap >= 0 &&
            nextXSnap <= contentHeight - offsetHeight &&
            nextY >= 0 &&
            nextY <= dayWidth - offsetWidth
          ) {
            const checkTopRange = () => {
              if (nextXSnap > elementChannel.top + elementChannel.height - 1) {
                return true;
              } else if (nextXSnap < elementChannel.top - 1) {
                return true;
              }
              return false;
            };
            const isOutTopRange = checkTopRange();

            if (!isOutTopRange) {
              elementRef.current!.style.top = `${nextY}px`;
              elementRef.current!.style.left = `${initialPosition.left}px`;
              setPosition((prev) => ({
                ...prev,
                y: nextY,
                x: initialPosition.left,
              }));
            } else {
              elementRef.current!.style.top = `${nextY}px`;
              elementRef.current!.style.left = `${nextXSnap}px`;
              setPosition((prev) => ({ ...prev, y: nextY, x: nextXSnap }));
            }
          }
        } else if (nextY >= 0 && nextY <= dayWidth - offsetWidth) {
          elementRef.current!.style.top = `${nextY}px`;
          setPosition((prev) => ({ ...prev, y: nextY }));
        }
      } else {
        const nextX = e.clientX - coords.startX + coords.lastX;

        if (isDndMutlirows) {
          const nextY = e.clientY - coords.startY + coords.lastY;
          const nextYSnap = Math.round(nextY / itemHeight) * itemHeight;
          if (
            nextYSnap >= 0 &&
            nextYSnap <= contentHeight - offsetHeight &&
            nextX >= 0 &&
            nextX <= dayWidth - offsetWidth
          ) {
            const checkTopRange = () => {
              if (nextYSnap > elementChannel.top + elementChannel.height - 1) {
                return true;
              } else if (nextYSnap < elementChannel.top - 1) {
                return true;
              }
              return false;
            };
            const isOutTopRange = checkTopRange();

            if (!isOutTopRange) {
              elementRef.current!.style.left = `${nextX}px`;
              elementRef.current!.style.top = `${initialPosition.top}px`;
              setPosition((prev) => ({
                ...prev,
                x: nextX,
                y: initialPosition.top,
              }));
            } else {
              elementRef.current!.style.top = `${nextYSnap}px`;
              elementRef.current!.style.left = `${nextX}px`;
              setPosition((prev) => ({ ...prev, x: nextX, y: nextYSnap }));
            }
          }
        } else if (nextX >= 0 && nextX <= dayWidth - offsetWidth) {
          elementRef.current!.style.left = `${nextX}px`;
          setPosition((prev) => ({ ...prev, x: nextX }));
        }
      }
    }
    if (isDndEnabled) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [
    isDndEnabled,
    isVerticalMode,
    isDragging,
    isDndMutlirows,
    initialPosition.top,
    initialPosition.left,
    channelPosition,
    coords,
    dayWidth,
    itemHeight,
    contentHeight,
    elementRef,
    handleMouseUp,
  ]);

  if (isDndEnabled) {
    return {
      dndEvents: {
        isDragging,
        onMouseDown: handleMouseDown,
        onMouseUp: handleMouseUp,
        ref: elementRef,
      },
      currentPositionX: position.x,
    };
  }

  return getDefaultDragProps(initialPosition.left);
}
