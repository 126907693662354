import React from "react";

// Import interfaces
import { Area, AreaItem } from "../helpers/interfaces";
// Import types
import { DateTime } from "../helpers/types";
// Import hooks
import { useAreas } from "../hooks/useAreas";

// Import styles
import { AreaStyled } from "../styles";

interface AreasProps {
  isVerticalMode: boolean;
  areas: Area[];
  height: number;
  hourWidth: number;
  sidebarWidth: number;
  timelineHeight: number;
  startDate: DateTime;
  endDate: DateTime;
}

type AreaAnnotationProps = Pick<AreaItem, "areaFieldStyles"> & {
  isLeft: boolean;
  text: string;
  styles: AreaItem["areaBgStyles"];
};

const { AreaAnnotation, AreaBg, AreaFiled } = AreaStyled;

export function Areas({ isVerticalMode, ...props }: AreasProps) {
  const { data } = useAreas(props);

  const renderAreaAnnotation = (props: AreaAnnotationProps) => {
    const { isLeft, text } = props;
    const { areaFieldStyles, styles } = props;
    const className = isLeft ? "left" : "right";
    return (
      <AreaAnnotation
        className={`planby-area-annotation-${className}`}
        isVerticalMode={isVerticalMode}
        isLeft={isLeft}
        timelineHeight={areaFieldStyles.timelineHeight}
        width={areaFieldStyles.width}
        style={styles}
      >
        {text}
      </AreaAnnotation>
    );
  };

  const renderArea = (props: AreaItem, index: number) => {
    const { showArea, annotations, onClick } = props;
    const { areaBgStyles = {}, areaFieldStyles } = props;
    if (!showArea) return null;

    const annotationProps = {
      areaFieldStyles,
      styles: annotations?.styles ?? {},
    };

    return (
      <AreaFiled
        key={index}
        className="planby-area-field"
        isVerticalMode={isVerticalMode}
        isClickable={!!onClick}
        onClick={onClick}
        {...areaFieldStyles}
      >
        <AreaBg className="planby-area-bg" style={areaBgStyles} />
        {annotations && (
          <>
            {annotations.textStart &&
              renderAreaAnnotation({
                isLeft: true,
                text: annotations.textStart,
                ...annotationProps,
              })}
            {annotations.textEnd &&
              renderAreaAnnotation({
                isLeft: false,
                text: annotations.textEnd,
                ...annotationProps,
              })}
          </>
        )}
      </AreaFiled>
    );
  };

  return (
    <React.Fragment>
      {data.map((props, index) => renderArea(props, index))}
    </React.Fragment>
  );
}
