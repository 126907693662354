import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Theme } from "../helpers";

export const boxStyles = ({ width }: { width?: number | string } = {}) => ({
  position: "absolute",
  top: 0,
  width: width ?? 70,
  height: 20,
});

export const CurrentTimeBox = styled.div<{
  top: number;
  left: number;
  width: number | string;
}>`
  position: ${boxStyles().position};
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  width: ${({ width }) => (typeof width === "string" ? "auto" : `${width}px`)};
  height: ${boxStyles().height}px;
  background-color: ${({ theme }) => theme.primary[900]};
  z-index: 2;
`;

export const CurrentTimeContent = styled.span<{
  isVerticalMode?: boolean;
  isBaseTimeFormat?: boolean;
  isRTL?: boolean;
  isNewDay?: boolean;
  theme?: Theme;
}>`
  ${({ isVerticalMode, isBaseTimeFormat, isRTL, isNewDay, theme }) => css`
    position: absolute;
    font-size: 13px;
    font-weight: 500;
    color: ${theme.green[200]};
    background-color: ${theme.primary[900]};
    ${isNewDay && `color: ${theme.teal[100]}; font-weight: 600`};

    ${isVerticalMode &&
    css`
      left: 50%;
      transform: translate(-50%, 0%) ${isRTL ? "scale(-1,1)" : ""};
    `}

    ${!isVerticalMode &&
    css`
      top: 4px;
      left: ${isRTL && isBaseTimeFormat ? "-32" : "-16"}px;
      ${isRTL && "transform: scale(-1,1);"}
    `}
  `}
`;
