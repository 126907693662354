import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Layers, Theme } from "../helpers";

export const TimelineTime = styled.span<{
  isVerticalMode?: boolean;
  isBaseTimeFormat?: boolean;
  isWeekMonthMode?: boolean;
  isRTL?: boolean;
  isNewDay?: boolean;
  theme?: Theme;
}>`
  position: absolute;
  top: 18px;
  left: ${({ isRTL, isBaseTimeFormat, isVerticalMode, isWeekMonthMode }) => {
    if (isVerticalMode) {
      if (isRTL) return isBaseTimeFormat ? 9 : 17;
      return isBaseTimeFormat ? 9 : 14;
    }
    if (isWeekMonthMode) return 0;
    return isRTL && isBaseTimeFormat ? "-32" : "-18";
  }}px;

  color: ${({ theme }) => theme.text.grey[300]};

  ${({ isRTL }) =>
    isRTL &&
    `
      transform: scale(-1, 1);
    `}

  ${({ theme, isNewDay }) =>
    isNewDay && `color: ${theme.teal[100]}; font-weight: 600`};

  ${({ isVerticalMode, isRTL }) =>
    isVerticalMode &&
    css`
      top: -10px;
      left: 50%;
      transform: translate(-50%, 0%) ${isRTL ? "scale(-1,1)" : ""};
    `};
`;
export const TimelineWeekMonthDate = styled.div<{
  isVerticalMode?: boolean;
  isRTL?: boolean;
  styleType?: "default" | "modern";
  theme?: Theme;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  transform: ${({ isRTL }) => (isRTL ? "scale(-1,1)" : "scale(1,1)")};

  span {
    color: ${({ theme }) => theme.text.grey[300]};
    font-size: 16px;
  }

  ${({ styleType, theme }) =>
    styleType === "modern" &&
    css`
      span:first-of-type {
        font-weight: 500;
        color: ${theme.teal[100]};
        font-size: 18px;
      }
      span {
        font-weight: 500;
        color: ${theme.text.grey[300]};
        font-size: 24px;
      }
    `}

  ${({ isVerticalMode, isRTL }) =>
    isVerticalMode &&
    css`
      margin-top: 0px;
      transform: ${isRTL ? "scale(-1,1)" : "scale(1,1)"};
    `};
`;

export const TimelineDividers = styled.div<{ isVerticalMode?: boolean }>`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: end;
  padding-bottom: 6px;

  ${({ isVerticalMode }) =>
    isVerticalMode &&
    css`
      grid-template-columns: 1fr;
      align-items: start;
    `}
`;

export const TimelineDivider = styled.div<{
  isVerticalMode?: boolean;
  isNewDay?: boolean;
  width: number;
  theme?: Theme;
}>`
  background: ${({ theme }) => theme.timeline.divider.bg};
  height: 10px;
  width: 1px;
  margin-right: ${({ width }) => width / 4}px;

  ${({ isVerticalMode }) =>
    isVerticalMode &&
    css`
      height: 1px;
      width: 15px;
      margin: 0 auto;
      &:first-of-type {
        opacity: 0;
        visibility: hidden;
      }
    `}

  ${({ isNewDay, theme }) =>
    isNewDay &&
    `&:first-of-type {
      width:2px;
      background: ${theme.teal[100]};
  }`}
`;

export const TimelineWrapper = styled.div<{
  isVerticalMode?: boolean;
  isSidebar: boolean;
  dayWidth: number;
  sidebarWidth: number;
  timelineHeight: number;
  theme?: Theme;
}>`
  ${({
    isVerticalMode,
    isSidebar,
    sidebarWidth,
    timelineHeight,
    dayWidth,
    theme,
  }) =>
    css`
      position: sticky;
      background: ${theme.primary[900]};
      z-index: ${Layers.Timeline};

      ${isVerticalMode &&
      css`
        left: 0;
        bottom: 0px;
        float: left;
        width: ${timelineHeight}px;
        margin-top: ${isSidebar ? sidebarWidth : 0}px;
      `}

      ${!isVerticalMode &&
      css`
        top: 0;
        left: ${isSidebar ? sidebarWidth : 0}px;
        height: ${timelineHeight}px;
        width: ${dayWidth}px;
      `}
    `}
`;

export const TimelineBox = styled.div<{
  isVerticalMode?: boolean;
  isWeekMonthMode?: boolean;
  isToday: boolean;
  isCurrentTime: boolean;
  isTodayInHoursInDays?: boolean;
  areHoursInDays?: boolean;
  width: number;
  left: number;
  timelineHeight: number;
}>`
  ${({ isVerticalMode, left, width, timelineHeight, theme }) => css`
    position: absolute;
    font-size: 14px;
    background-color: ${theme.primary[900]};

    ${!isVerticalMode &&
    css`
      left: ${left}px;
      width: ${width}px;
      height: 100%;
    `}
    ${isVerticalMode &&
    css`
      top: ${left}px;
      height: ${width}px;
      width: ${timelineHeight}px;
    `}
  `}

  ${({
    isVerticalMode,
    isToday,
    isCurrentTime,
    isTodayInHoursInDays,
    areHoursInDays,
    isWeekMonthMode,
  }) => {
    const typeNumberDefault = isToday && isCurrentTime ? 2 : 1;
    const typeNumberInHoursDays = areHoursInDays
      ? isTodayInHoursInDays && isCurrentTime
        ? 2
        : 1
      : 1;
    const typeNumber = areHoursInDays
      ? typeNumberInHoursDays
      : typeNumberDefault;
    const position = isVerticalMode ? "top" : "left";

    if (!isWeekMonthMode) {
      return `&:nth-of-type(${typeNumber}) {
        ${TimelineTime} {
          ${position}: 0px;
        }
      }`;
    }
    return "";
  }}
`;
export const TimelineWeekMonthBox = styled(TimelineBox)<{
  isVerticalMode?: boolean;
  isWeekMonthMode?: boolean;
  isToday: boolean;
  isCurrentTime: boolean;
  isTodayInHoursInDays?: boolean;
  areHoursInDays?: boolean;
  width: number;
  left: number;
  timelineHeight: number;
  styleType?: "default" | "modern";
}>`
  ${({ isVerticalMode, styleType, theme }) =>
    css`
      &:not(:last-of-type) {
        &:after {
          content: "";
          position: absolute;
          background: ${theme.timeline.divider.bg};
          transform: translate(-50%, -50%);

          ${isVerticalMode
            ? css`
                bottom: 0;
                height: 1px;
                width: 80%;
              `
            : css`
                top: 50%;
                right: 0;
                height: 100%;
                height: ${styleType === "modern" ? "35%" : "70%"};
                width: 1px;
              `}
        }
      }
    `}
`;
