import * as React from "react";

// Import interfaces
import { Timeline as ITimeline } from "../helpers/interfaces";

// Import styles
import { TimelineStyled } from "../styles";

// Import components
import { CurrentTime } from "./CurrentTime";

// Import hooks
import { useTimeline } from "../hooks";
import {
  TimelineWeekMonthBox,
  TimelineWeekMonthDate,
} from "../styles/Timeline.styles";

const {
  TimelineWrapper,
  TimelineBox,
  TimelineTime,
  TimelineDividers,
  TimelineDivider,
} = TimelineStyled;

export function Timeline(props: ITimeline) {
  const {
    isWeekMonthMode,
    isMonthMode,
    isTodayInHoursInDays,
    areHoursInDays,
    time,
    ...rest
  } = useTimeline(props);
  const { timelineHeight, weekDayWidth, monthsWidth, dividers } = rest;
  const {
    formatWeekMonthDate,
    getTime,
    getDayMonthName,
    getTimelineProps,
    getCurrentTimeProps,
  } = rest;

  const {
    isToday,
    isBaseTimeFormat,
    isCurrentTime,
    isRTL,
    isTimelineVisible,
    isVerticalMode,
  } = props;
  const { mode } = props;
  const { hourWidth } = props;

  // Handlers
  const renderDay = (item: string | number, index: number) => {
    const { isNewDay, time } = getTime(item);
    const position = { left: hourWidth * index, width: hourWidth };
    const isVisible = isTimelineVisible(position);
    if (!isVisible) return null;

    return (
      <TimelineBox
        className="planby-timeline-box"
        data-testid="timeline-item"
        key={index}
        isToday={isToday}
        isCurrentTime={isCurrentTime}
        isVerticalMode={isVerticalMode}
        isTodayInHoursInDays={isTodayInHoursInDays}
        areHoursInDays={areHoursInDays}
        timelineHeight={timelineHeight}
        {...position}
      >
        <TimelineTime
          className="planby-timeline-time"
          isNewDay={isNewDay}
          isBaseTimeFormat={isBaseTimeFormat}
          isRTL={isRTL}
          isVerticalMode={isVerticalMode}
        >
          {time}
        </TimelineTime>
        <TimelineDividers
          className="planby-timeline-dividers"
          isVerticalMode={isVerticalMode}
        >
          {renderDividers(isNewDay)}
        </TimelineDividers>
      </TimelineBox>
    );
  };
  const renderWeekMonth = (item: string, index: number) => {
    const width = isMonthMode ? monthsWidth[index].width : weekDayWidth;
    const left = isMonthMode ? monthsWidth[index].left : width * index;
    const position = {
      left,
      width,
    };
    const isVisible = isTimelineVisible(position);
    if (!isVisible) return null;
    const isModernStyle = mode.style === "modern";
    return (
      <TimelineWeekMonthBox
        className="planby-timeline-box"
        data-testid="timeline-item"
        key={index}
        isToday={isToday}
        isWeekMonthMode={isWeekMonthMode}
        isCurrentTime={isCurrentTime}
        isVerticalMode={isVerticalMode}
        timelineHeight={timelineHeight}
        styleType={mode.style}
        {...position}
      >
        <TimelineWeekMonthDate
          className="planby-timeline-week-month-date"
          isRTL={isRTL}
          isVerticalMode={isVerticalMode}
          styleType={mode.style}
        >
          {isModernStyle && <span>{getDayMonthName(item)}</span>}
          <span>{formatWeekMonthDate(item)}</span>
        </TimelineWeekMonthDate>
      </TimelineWeekMonthBox>
    );
  };

  const renderDividers = (isNewDay: boolean) =>
    dividers.map((_, index) => (
      <TimelineDivider
        key={index}
        className="planby-timeline-divider"
        isVerticalMode={isVerticalMode}
        isNewDay={isNewDay}
        width={hourWidth}
      />
    ));

  const renderTimelineItems = (item: string | number, index: number) => {
    return isWeekMonthMode
      ? renderWeekMonth(item as string, index)
      : renderDay(item, index);
  };

  return (
    <TimelineWrapper
      className="planby-timeline-wrapper"
      data-testid="timeline"
      {...getTimelineProps()}
    >
      {isCurrentTime && isToday && <CurrentTime {...getCurrentTimeProps()} />}
      {time.map((item, index) => renderTimelineItems(item, index))}
    </TimelineWrapper>
  );
}
