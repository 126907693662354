import { format, addMinutes, differenceInMinutes } from "date-fns";
// Import types
import { DateTime, DragMouseUp, ResizeMouseUp } from "./types";
// Import variables
import { TIME_FORMAT } from "./variables";

type EventProps = Pick<
  DragMouseUp | ResizeMouseUp,
  keyof DragMouseUp & keyof ResizeMouseUp
>;
type EventSinceTill = EventProps & {
  startDate?: DateTime;
  hourWidth: number;
  width?: ResizeMouseUp["width"];
  initialWidth?: ResizeMouseUp["initialWidth"];
};

const getResizeTill = ({
  initialWidth,
  width,
  hourWidth,
}: Partial<Pick<ResizeMouseUp, "width" | "initialWidth">> & {
  hourWidth: number;
}) => {
  if (!initialWidth && !width) return 0;
  const diff = (width as number) - (initialWidth as number);
  const diffHours = diff / hourWidth;

  return diffHours * 60;
};

export const calculateItemDragSinceTill = (props: EventSinceTill) => {
  const {
    initialPositionLeft,
    left,
    initialWidth,
    width,
    hourWidth,
    startDate,
    since,
    till,
  } = props;
  let minDiff = 0;
  if (startDate) {
    const startDateTime = new Date(startDate).getTime();
    const sinceTime = new Date(since).getTime();
    if (sinceTime < startDateTime) {
      minDiff = differenceInMinutes(new Date(startDate), new Date(since));
    }
  }
  const diff = left - initialPositionLeft;
  const diffHours = diff / hourWidth;
  const tillDiffHours = getResizeTill({ initialWidth, width, hourWidth });
  const newSince = addMinutes(new Date(since), diffHours * 60 + minDiff);
  const newTill = addMinutes(
    new Date(till),
    diffHours * 60 + tillDiffHours + minDiff
  );
  return {
    since: format(newSince, TIME_FORMAT.DEFAULT).replace(" ", "T"),
    till: format(newTill, TIME_FORMAT.DEFAULT).replace(" ", "T"),
  };
};

export const getDefaultDragProps = (positionLeft: number) => ({
  currentPositionX: positionLeft,
  dndEvents: { isDragging: false },
});
