import React from "react";
import { format } from "date-fns";

// Import types
import { BaseTimeFormat } from "../helpers/types";

// Import helpers
import { Mode, TIME_FORMAT } from "../helpers";

// Import styles
import { boxStyles } from "../styles/CurrentTime.styles";

interface useCurrentTimeProps {
  isVerticalMode?: boolean;
  isBaseTimeFormat?: BaseTimeFormat;
  mode: Mode;
  positionX: number;
  timelineHeight: number;
}

export function useCurrentTime({
  isVerticalMode,
  isBaseTimeFormat,
  mode,
  positionX,
  timelineHeight,
}: useCurrentTimeProps) {
  const formatTime = () => {
    const date = new Date();

    if (mode.type === "month") {
      if (isBaseTimeFormat) return format(date, TIME_FORMAT.BASE_DAY_MONTH);
      return format(date, TIME_FORMAT.DAY_MONTH);
    }

    const baseTime = format(date, TIME_FORMAT.HOURS_MIN);

    if (isBaseTimeFormat) {
      const baseFormat = format(date, TIME_FORMAT.BASE_HOURS_TIME)
        .toLowerCase()
        .replace(/\s/g, "");

      return baseFormat;
    }

    return baseTime;
  };

  const handleGetCurrentTimeStyles = () => {
    const {
      position,
      top,
      height,
      width: styleWidth,
    } = boxStyles({ width: timelineHeight });
    const width = isVerticalMode ? styleWidth : "auto";
    const commonStyles = {
      position: position,
      width,
      height: height,
    };

    if (isVerticalMode) {
      return {
        position: {
          top: positionX - 111,
          left: top,
          ...commonStyles,
        },
      };
    }

    return {
      position: {
        top: top,
        left: positionX - 100,
        ...commonStyles,
      },
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const time = React.useMemo(() => formatTime(), [positionX, isBaseTimeFormat]);

  return { time, getCurrentTimeStyles: handleGetCurrentTimeStyles };
}
