import * as React from "react";

// Import interfaces
import {
  DragAndDrop,
  Mode,
  Program as ProgramType,
} from "../helpers/interfaces";

// Import types
import { BaseTimeFormat, DragMouseUp, ResizeMouseUp } from "../helpers/types";
import { ProgramItem } from "../helpers/types";

// Import styles
import { ProgramStyled } from "../styles";

// Import hooks
import { useProgram } from "../hooks";

interface ProgramProps<T> {
  isVerticalMode?: boolean;
  isRTL?: boolean;
  isResize?: boolean;
  isBaseTimeFormat: BaseTimeFormat;
  program: T;
  liveRefreshTime: number;
  mode: Mode;
  dnd: DragAndDrop;
  contentHeight: number;
  dayWidth: number;
  hourWidth: number;
  itemHeight: number;
  dragMouseUp: (data: DragMouseUp) => void;
  resizeMouseUp: (data: ResizeMouseUp) => void;
  onClick?: (v: ProgramType) => void;
}

const {
  ProgramBox,
  ProgramContent,
  ProgramResizeHandle,
  ProgramFlex,
  ProgramStack,
  ProgramTitle,
  ProgramText,
  ProgramImage,
} = ProgramStyled;

export function Program<T extends ProgramItem>({
  isVerticalMode,
  program,
  onClick,
  ...rest
}: ProgramProps<T>) {
  const {
    isRTL,
    isLive,
    isMinWidth,
    isMouseEvent,
    styles,
    resizeEvents,
    formatTime,
    getMouseEvents,
    getMouseEventTempTime,
    set12HoursTimeFormat,
    getRTLSinceTime,
    getRTLTillTime,
  } = useProgram({
    isVerticalMode,
    program,
    ...rest,
  });

  const { data } = program;
  const { image, title, since, till } = data;

  const handleOnContentClick = () => onClick?.(data);

  const sinceTime = formatTime(
    getRTLSinceTime(since),
    set12HoursTimeFormat()
  ).toLowerCase();

  const tillTime = formatTime(
    getRTLTillTime(till),
    set12HoursTimeFormat()
  ).toLowerCase();

  const tempTime = getMouseEventTempTime();

  return (
    <ProgramBox
      id={data.id}
      className="planby-program"
      data-testid="program-item"
      width={styles.width}
      style={styles.position}
      {...getMouseEvents()}
    >
      <ProgramContent
        className="planby-program-content"
        data-testid="program-content"
        width={styles.width}
        isLive={isLive}
        isVerticalMode={isVerticalMode}
        onClick={handleOnContentClick}
        {...rest}
      >
        <ProgramResizeHandle {...resizeEvents.eventsLeft} />
        <ProgramResizeHandle {...resizeEvents.eventsRight} />
        <ProgramFlex
          className="planby-program-flex"
          isVerticalMode={isVerticalMode}
        >
          {isLive && isMinWidth && (
            <ProgramImage
              isVerticalMode={isVerticalMode}
              src={image}
              alt="Preview"
            />
          )}
          <ProgramStack className="planby-program-stack" isRTL={isRTL}>
            <ProgramTitle className="planby-program-title">
              {title}
            </ProgramTitle>
            <ProgramText
              className="planby-program-text"
              aria-label="program time"
            >
              {isMouseEvent ? (
                <>
                  {tempTime.since} - {tempTime.till}
                </>
              ) : (
                <>
                  {sinceTime} - {tillTime}
                </>
              )}
            </ProgramText>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
}
