import styled from "@emotion/styled/macro";
import { Layers, Theme } from "../helpers";

export const ProgramContent = styled.div<{
  isVerticalMode?: boolean;
  isLive: boolean;
  width: number;
  theme?: Theme;
}>`
  border: 1px solid #171923;
  position: relative;
  display: flex;
  font-size: 11px;
  height: 100%;
  border-radius: 8px;
  padding: 10px ${({ width }) => (width < 30 ? 4 : 20)}px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  background: ${({ theme: { primary } }) =>
    `linear-gradient(to right, ${primary[600]}, ${primary[600]})`};
  z-index: ${Layers.Program};

  &:hover {
    background: ${({ theme: { gradient } }) =>
      `linear-gradient(to right, ${gradient.blue[900]}, ${gradient.blue[600]})`};
  }

  ${({ isLive, isVerticalMode, theme: { gradient } }) =>
    isLive &&
    `background: linear-gradient(to ${isVerticalMode ? "bottom" : "right"}, ${
      gradient.blue[900]
    }, ${gradient.blue[600]},${gradient.blue[300]})`}
`;

export const ProgramFlex = styled.div<{ isVerticalMode?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: flex-start;

  ${({ isVerticalMode }) => isVerticalMode && `flex-direction: column;`};
`;

const Elipsis = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProgramTitle = styled.p<{ theme?: Theme }>`
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 500;
  color: ${({ theme }) => theme.grey[300]};
  ${Elipsis}
`;

export const ProgramText = styled.span<{ theme?: Theme }>`
  display: block;
  font-size: 12.5px;
  font-weight: 400;
  color: ${({ theme }) => theme.text.grey[500]};
  text-align: left;
  ${Elipsis}
`;

export const ProgramImage = styled.img<{ isVerticalMode?: boolean }>`
  margin-right: 15px;
  border-radius: 6px;
  width: 100px;

  ${({ isVerticalMode }) =>
    isVerticalMode && `width: 100%; margin-bottom: 10px`};
`;

export const ProgramStack = styled.div<{ isRTL?: boolean }>`
  overflow: hidden;
  ${({ isRTL }) =>
    isRTL &&
    `transform: scale(-1,1); 
     display: flex; 
     flex-direction: column; 
     align-items: flex-end`};
`;

export const ProgramBox = styled.div<{
  isResizing?: boolean;
  isDragging?: boolean;
  width: number;
}>`
  position: absolute;
  padding: ${({ width }) => (width === 0 ? 0 : 4)}px;
  z-index: ${({ isDragging, isResizing }) =>
    isDragging || isResizing ? Layers.Program * 3 : Layers.Program};
  overflow: hidden;

  ${({ isDragging, isResizing }) =>
    (!isDragging || !isResizing) &&
    ` &:hover {
    z-index: ${Layers.Program * 2};

    ${ProgramResizeHandle} {
      opacity: 1;
    }
  }`}

  ${({ isDragging, isResizing, theme }) =>
    (isDragging || isResizing) &&
    ` 
    border-radius: 10px;
    background: ${theme.green[200]};
    
    ${ProgramContent}{
      user-select: none;
      cursor: move;
  }
  `}
`;

export const ProgramResizeHandle = styled.div<{
  isResize?: boolean;
  isVerticalMode?: boolean;
  left?: boolean;
}>`
  position: absolute;
  opacity: 0;

  ${({ isResize }) => !isResize && "display: none;"}

  ${({ isVerticalMode, left }) =>
    isVerticalMode &&
    `
    ${left ? `top: 0;` : `bottom: 0;`}
    left:0;
    height:25px;
    width: 100%;
  cursor: ${left ? "n-resize" : "s-resize"};
  `}
  ${({ isVerticalMode, left }) =>
    !isVerticalMode &&
    `
    top: 0;
    bottom: 0;
    ${left ? `left: 0;` : `right: 0;`}
    width: 25px;
    cursor: ${left ? "w-resize" : "e-resize"};
    `}

  &:after {
    content: "";
    position: absolute;
    margin: auto;
    border-radius: 8px;
    background: ${({ theme }) => theme.green[200]};

    ${({ isVerticalMode, left }) =>
      !isVerticalMode &&
      `   top: 0;
      bottom: 0;
      height: 50%;
      width: 4px;
        ${left ? `left: 9px;` : `right: 9px;`}
        `}
    ${({ isVerticalMode, left }) =>
      isVerticalMode &&
      `
      ${left ? `top: 9px;` : `bottom: 9px;`}
     left:50%;
      height: 4px;
      width: 50%;
      transform: translateX(-50%);
     
        `}
  }
`;
