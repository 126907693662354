import * as React from "react";
// Import interfaces
import { ChannelItem, ChannelWithPosition } from "../helpers/types";

// Import helpers
import { getChannelVerticalPosition } from "../helpers";

// Import styles
import { ChannelsStyled } from "../styles";

// Import Components
import { Channel } from "../components";

interface ChannelsProps {
  isVerticalMode: boolean;
  isTimeline: boolean;
  isRTL: boolean;
  isChannelVisible: (position: any) => boolean;
  channels: ChannelWithPosition[];
  contentHeight: number;
  sidebarWidth: number;
  timelineHeight: number;
  scrollY: number;
  renderChannel?: (v: ChannelItem) => React.ReactNode;
}

const { Box } = ChannelsStyled;

export function Channels(props: ChannelsProps) {
  const {
    channels,
    scrollY,
    sidebarWidth,
    timelineHeight,
    contentHeight,
    renderChannel,
  } = props;
  const { isVerticalMode, isRTL, isTimeline, isChannelVisible } = props;

  const renderChannels = (channel: ChannelWithPosition) => {
    const newChannel = getChannelVerticalPosition(channel, isVerticalMode);
    const isVisible = isChannelVisible(newChannel.position);

    if (isVisible) {
      if (renderChannel) {
        return renderChannel({ channel: newChannel, isVerticalMode, isRTL });
      }

      return (
        <Channel
          key={channel.uuid}
          channel={newChannel}
          isVerticalMode={isVerticalMode}
        />
      );
    }
    return null;
  };

  return (
    <Box
      className="planby-channels"
      data-testid="sidebar"
      isVerticalMode={isVerticalMode}
      isRTL={isRTL}
      isTimeline={isTimeline}
      timelineHeight={timelineHeight}
      sidebarWidth={sidebarWidth}
      contentHeight={contentHeight}
      bottom={scrollY}
    >
      {channels.map(renderChannels)}
    </Box>
  );
}
