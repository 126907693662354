import { css } from "@emotion/react";
import styled from "@emotion/styled/macro";
import { Layers, Theme } from "../helpers";

export const Box = styled.div<{
  isVerticalMode?: boolean;
  isTimeline: boolean;
  isRTL: boolean;
  sidebarWidth: number;
  timelineHeight: number;
  contentHeight: number;
  bottom: number;
  theme?: Theme;
}>`
  ${({
    isVerticalMode,
    isTimeline,
    isRTL,
    sidebarWidth,
    timelineHeight,
    contentHeight,
    bottom,
    theme,
  }) => css`
    position: sticky;
    background-color: ${theme.primary[900]};

    ${isRTL && `& > div { transform: scale(-1,1) }`};

    ${isVerticalMode &&
    css`
      top: 0;
      left: ${isTimeline ? timelineHeight : 0}px;
      height: ${sidebarWidth}px;
      width: ${contentHeight}px;
    `}

    ${!isVerticalMode &&
    css`
      left: 0;
      bottom: ${bottom}px;
      float: left;
      width: ${sidebarWidth}px;
    `}
  `}
  z-index: ${Layers.Sidebar};
`;
